import React, { useState } from "react";

import styles from "./AllUsersTicketHistoryTable.module.css";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { axiosConfig } from "../../../../axios/axiosConfig";

const AllUsersTicketHistoryTable = ({ title="" , userType
  // userTickets = [],
  // setAllUsersTicketsList,
}) => {
  const navigate = useNavigate();

  const [allUsersTicketsList, setAllUsersTicketsList] = useState([]);
  
  // FETCHING all user tickets raised
  const allUserTicketsQuery = useQuery({
    queryKey: ["all-users-tickets"],
    // enabled: false,
    refetchOnWindowFocus: false,
    queryFn: () => fetchAllUsersTickets(),
    onSuccess: (data) => {
      setAllUsersTicketsList(data.response);
    },
    onError: (error) => {
      console.log("fetch Symbols error: ", error);
    },
  });

  // post comment on user ticket
  const deleteCommentMutation = useMutation({
    mutationFn: (variables) => deleteTicket(variables),
    onSuccess: (data, variables) => {
      const { ticket_id } = variables;

      setAllUsersTicketsList((prevState) => {
        return prevState.filter((item) => item.id !== ticket_id);
      });

      window.alert(data.response);
    },
    onError: (error) => {
      console.log("del comment error: ", error);
    },
    onSettled: (data) => {},
  });

  const handleDeleteComment = (ticket_id) => {
    deleteCommentMutation.mutate({ ticket_id });
  };

  return (
    <div className={styles.mainContainer} >
      <h1 className="topHeadingUnderline" >{title}</h1>

      <h2 className={styles.mainTitle} >Users Ticket History</h2>

      {/* all users ticket history table */}
      <table className={styles.tableContainer}>
        <thead>
          <tr>
            <th>Ticket ID</th>
            <th>Mobile</th>
            <th>User Name</th>
            <th>Title</th>
            <th>Description</th>
            <th>Created On</th>
            <th>Status</th>
            <th>Priority</th>
            <th>Last Updated</th>
            <th>Actions</th>
          </tr>
        </thead>

        <tbody className={styles.tableBodyContainer}>
          {allUsersTicketsList.map((item) => {
            const createdDate = new Date(item.created_at).toDateString();
            const modifiedDate = new Date(item.modified_at).toDateString();
            // const isTicketChecked = item?.isticketcheck;

            const isTicketCheckedStyles = !item?.isticketcheck
              ? {
                  backgroundColor: "#F1BBB8",
                  fontWeight: "bold",
                  color: "#333",
                  // color: "#e2e2e2"
                }
              : null;

            return (
              <tr key={item.id} style={isTicketCheckedStyles}>
                <td>{item.ticket_id}</td>
                <td>{item?.requester_phone}</td>
                <td>{item.requester_name}</td>
                <td>
                  {item.title.length > 20
                    ? `${item.title.substring(0, 20)}...`
                    : item.title}
                </td>
                <td>
                  {item.description.length > 30
                    ? `${item.description.substring(0, 30)}...`
                    : item.description}
                </td>
                <td>{createdDate}</td>
                <td>{item.status}</td>
                <td>{item.priority}</td>
                <td>{modifiedDate}</td>

                <td>
                  <div className={styles.viewBtnContainer}>
                    <button
                      className={styles.viewBtn}
                      onClick={() =>
                        navigate(`/${userType}/user-support/${item.id}`)
                      }
                    >
                      view
                    </button>

                    {/* {deleteCommentMutation.isLoading ? (
                      "..."
                    ) : (
                      <button onClick={() => handleDeleteComment(item.id)}>
                        delete
                      </button>
                    )} */}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default AllUsersTicketHistoryTable;

// UTILITY FUNCTIONS
const deleteTicket = async ({ ticket_id }) => {
  if (!ticket_id) return;

  const res = await axiosConfig({
    method: "delete",
    url: "/support/raise_request",
    data: {
      id: ticket_id,
    },
  });

  return res.data;
};


const fetchAllUsersTickets = async () => {
  // if (!user_id) return;

  const res = await axiosConfig({
    method: "get",
    url: "/support/raise_request",
    data: {},
  });

  return res.data;
};