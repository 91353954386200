import React, { useState } from "react";
// import UploadIcon from "@mui/icons-material/Upload";

import styles from "./Banner.module.css";
import { useMutation, useQuery } from "@tanstack/react-query";
import { axiosConfig } from "../../../../axios/axiosConfig";
import BannerImageTable from "./BannerImageTable";
import toast from "react-hot-toast";
import MuiLoadingSpinner from "../../../common/MuiLoadingSpinner";

const Banner = () => {
  const [imgFile, setImgFile] = useState();
  const [imgPreview, setImgPreview] = useState(null);

  
  const [formInput, setFormInput] = useState({
    imgUrl: "",  // blob preview url
    customUrl: "",
    targetUrl: "",
  });

  const [bannerImagesList, setBannerImagesList] = useState([]);
  
  // fetch all banner images
  const fetchBannerImagesQuery = useQuery({
    retry: false,
    refetchOnWindowFocus: false, // Prevent refetch on window focus
    refetchInterval: false, // Disable automatic refetching
    queryKey: ["banner-images"], // dependency
    queryFn: () => fetchBannerImages(),
    onSuccess: (data) => {
      setBannerImagesList(data.response);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.response || "Something went wrong");
    },
  });

  const handleFormInputOnChange = (event) => {
    setFormInput((prevState) => {
      return {
        ...prevState,
        [event.target.name]: event.target.value,
      };
    });

    // image preview code
    if (event.target.name === "imgUrl") {
      setImgPreview(event.target.value);
    }
  };

  function handleImageFileChange(e) {
    setImgPreview(URL.createObjectURL(e.target.files[0]));
    setImgFile(e.target.files[0]);
    
    setFormInput((prev) => {
      return {
        ...prev,
        // imgUrl: URL.createObjectURL(e.target.files[0]),
        imgUrl: e.target.files[0]?.name,
      };
    });
  }

  // upload home banner
  const uploadHomeBannerMutation = useMutation({
    mutationFn: (variables) => uploadHomeBanner(formInput, imgFile),
    onSuccess: (data) => {
      // setBannerImagesList()
      alert('Image uploaded successfully!');
    },
    onError: (error) => {
      alert(error?.response?.data?.response);
    },
    onSettled: (data) => {},
  });

  // HANDLE SUBMIT
  const handleSubmit = (e) => {
    // e.preventDefault();

    uploadHomeBannerMutation.mutate();
  };

  return (
    <div>
      <h2>Banner Form</h2>

      <p className={styles.description} >This form is to upload banner images for home screen carousel</p>

      {/* Banner form */}
      {/* name?, description?, image url / upload, deeplink / Launch URL */}
      <div className={styles.formPreviewContainer}>
        <div className={styles.bannerForm}>
          {/* <div className={styles.inputBox}>
            <label htmlFor="title">Title</label>
            <input
              className={styles.inputControl}
              type="text"
              name="title"
              id="title"
              value={formInput.title}
              onChange={handleFormInputOnChange}
            />
          </div>

          <div className={styles.inputBox}>
            <label htmlFor="description">description</label>
            <input
              className={styles.inputControl}
              type="text"
              name="description"
              id="description"
              value={formInput.description}
              onChange={handleFormInputOnChange}
            />
          </div> */}

          <div className={styles.inputBox}>
            <label htmlFor="title">Image file</label>

            <div className={styles.imgUploadContainer}>
              <input
                disabled
                className={styles.imgUrlInput}
                type="text"
                name="imgUrl"
                id="imgUrl"
                value={formInput.imgUrl}
                onChange={handleFormInputOnChange}
              />
              <label htmlFor="imageUpload">
                {/* <UploadIcon /> */}
                <span className={styles.uploadBtn}>upload</span>
                <input
                  onChange={handleImageFileChange}
                  className={styles.imgFileInput}
                  type="file"
                  name="imageUpload"
                  id="imageUpload"
                />
              </label>
            </div>
          </div>

          {/* CUSTOM URL */}
          <div className={styles.inputBox}>
            <label htmlFor="customUrl">Custom Link</label>
            <input
              className={styles.inputControl}
              type="text"
              name="customUrl"
              id="customUrl"
              value={formInput.customUrl}
              onChange={handleFormInputOnChange}
            />
          </div>

          {/* target url */}
          <div className={styles.inputBox}>
            <label htmlFor="targetUrl">Target Link</label>
            <input
              className={styles.inputControl}
              type="text"
              name="targetUrl"
              id="targetUrl"
              value={formInput.targetUrl}
              onChange={handleFormInputOnChange}
            />
          </div>

          <div className={styles.submitBtnContainer} >
            <button className={styles.submitBtn} onClick={handleSubmit}>
              submit
            </button>
          </div>
        </div>

        {/* Image Preview Container */}
        <div className={styles.imgPreviewContainer}>
          <div className={styles.imgContainer}>
            <img className={styles.imgPreview} src={imgPreview} alt="" />
          </div>
        </div>
      </div>

      {/* Banner Table */}
      {fetchBannerImagesQuery?.isLoading ? <MuiLoadingSpinner /> : <BannerImageTable bannerImagesList={bannerImagesList} setBannerImagesList={setBannerImagesList} /> }
      
    </div>
  );
};

export default Banner;

// utilities
const uploadHomeBanner = async (form_input, img_file) => {
  // if (!ticket_id) return;

 const formData = new FormData();
 formData.append('image', img_file);
 formData.append('image_link', form_input?.customUrl);
 formData.append('jump_screen_link', form_input?.targetUrl);

  const res = await axiosConfig({
    method: "post",
    url: "/home/banner",
    data: formData,
    // data: {
    //   image: img_file,
    //   image_link: form_input?.customUrl,
    //   jump_screen_link: form_input?.targetUrl,
    // },
  });

  return res.data;
};

// UTILITY FUNCTIONS
export const fetchBannerImages = async () => {
  // if (!USERID) return;

  const res = await axiosConfig({
    method: "get",
    url: "/home/banner",
    data: {},
  });

  return res.data;
};
