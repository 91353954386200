import React, { useState } from "react";

import { useDispatch } from "react-redux";

import { register } from "../../reduxStore/actions/auth";

import BasicButton from "../Button/BasicButton";
import Loader from "../Loading/Loading";
import BasicTextFields from "../Modal/Input";
import { useNavigate, useSearchParams } from "react-router-dom";

// import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/style.css";
import "react-phone-input-2/lib/material.css";
import { Button, Stack, TextField, Typography } from "@mui/material";

import axiosConfig from "../../axios/axiosConfig";
import MobilePhoneInput from "./MobilePhoneInput";
import ReactHotToaster from "../common/ReactHotToaster";
import { toast } from "react-hot-toast";
// import { sendDeviceID } from "../../utils/notifications";

// REFERRAL LINK: http://localhost:3000/register?referral_code=IRD2HMU7G6XY

const SignUpPage = ({
  // displaySignUpPage,
  setDisplaySignUpPage,
  userDetails,
  setUserDetails,
  phoneInputOnChange,
  routeToHome,
}) => {
  // const [userFullName, setUserFullName] = useState("NONAME");
  const userFullName = "NONAME";

  // state for user input details
  const [userInputs, setUserInputs] = useState({
    firstName: "",
    lastName: "",
    userEmail: "",
  });
  const { firstName, lastName, userEmail } = userInputs;

  // const [userEmail, setUserEmail] = useState(null);
  const [userPassword, setUserPassword] = useState("");
  const [reUserPassword, setReUserPassword] = useState("");

  const [mobileOTP, setMobileOTP] = useState("");
  const [sessionID, setSessionID] = useState("");

  const navigate = useNavigate();
  // const location = useLocation();
  const [searchParams] = useSearchParams();
  const REFERRAL_CODE = searchParams.get("referral_code");

  // const [userDetails, setUserDetails] = useState({
  //   phone: "",
  //   country: "",
  //   countryCode: "in",
  // });

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  // not being used, BasicTExtfields email commented out
  // const handleUserEmail = (key, val) => {
  //   setUserEmail(val);
  // };

  const handleUserPassword = (key, val) => {
    setUserPassword(val);
  };

  const handleReUserPassword = (key, val) => {
    setReUserPassword(val);
  };

  // const handleUserPhone = (key, val) => {
  //   setUserPhone(val);
  // };

  const displayErrorMessage = (res) => {
    let errorMessage;
    if (res.data.response?.email) {
      errorMessage = res.data.response.email;
    } else if (res.data.response) {
      errorMessage = res.data.response;
    }

    return errorMessage;
  };

  // SETTING TOKEN AND USER DETAILS IN LOCAL STORAGE
  const setLocalStorage = (responseData) => {
    if (responseData.access_token) {
      const allUserDetails = {
        ...responseData,
        userDetails: responseData.response,
      };
      localStorage.setItem("user", JSON.stringify(allUserDetails));

      // window.location.reload();
      // window.location.assign("/");
      routeToHome();
    }
  };

  const handleSubmitRegister = () => {
    const isPasswordValid = checkPassword(userPassword);
    if (!isPasswordValid) {
      toast.error(
        "Password must be a minimum of 8 characters, with at least 1 symbol, upper and lower case letters and a number",
        { position: "top-right", hideAfter: 8 }
      );
      return;
    }

    if (userPassword !== reUserPassword) {
      toast.error("Password did'nt match", { position: "top-right" });
      return;
    }

    setIsLoading(true);
    dispatch(
      register(
        userFullName,
        userEmail,
        userPassword,
        userDetails.phone.slice(2),
        userDetails.country,
        REFERRAL_CODE
      )
    )
      .then((res) => {
        // console.log("res: ", res)
        setIsLoading(false);

        if (res.data.status === "success") {
          setLocalStorage(res.data);
          toast.success("Account created successfully");
          // displaySignUpPage(res);
          setDisplaySignUpPage(false);
          navigate("/home");
        }

        if (res.data.status === "failed") {
          const message = displayErrorMessage(res);
          toast.error(message);
        }
      })
      .catch((err) => {
        if (err.response.data?.Status === "Error") {
          toast.error(err.response.data.Details);
        }
        setIsLoading(false);
      });
  };

  // // onchange handler for phone, country
  // const phoneInputOnChange = (value, data, event, formattedValue) => {
  //   // console.log({value, data, event, formattedValue })

  //   setUserDetails((prev) => {
  //     return {
  //       ...prev,
  //       phone: value,
  //       country: data.name,
  //       countryCode: data.countryCode,
  //     };
  //   });
  // };

  // GET OTP for Indian users, send all details (phone, email and password)
  // Use 'getOTP' only for Indian Users, use 'handleSubmitRegister' above for Non-Indians.
  const getOTP = async () => {
    try {
      const res = await axiosConfig({
        method: "post",
        url: "/enroll/signup",
        data: {
          phoneno: parseInt(userDetails.phone.slice(2)),
          // phoneno: userDetails.phone.slice(2),
          email: userEmail?.trim(),
          password: userPassword,
          country: userDetails.country,
        },
      });

      // If successful
      if (res.status === 200 || res.statusText === "OK") {
        setSessionID(res.data.Details);
        toast.success(
          "OTP sent to mobile, please type OTP and click Verify OTP"
        );
      }
    } catch (error) {
      console.log(error);
      const ERROR_MSG = error.response.data.response;
      toast.error(ERROR_MSG || "Something went wrong", {
        duration: 6000,
        position: "top-center",
      });
    }
  };

  // Verify OTP and Login (FOR INDIAN USERS ONLY)
  const verifyOTPLogin = async () => {
    try {
      const res = await axiosConfig({
        method: "post",
        url: "/enroll/verifyotp",
        data: {
          session_id: sessionID,
          otp: parseInt(mobileOTP),
          phoneno: parseInt(userDetails.phone.slice(2)),
          country: userDetails.country,
          // referral_code: REFERRAL_CODE,
          referred_by: REFERRAL_CODE,
        },
      });

      if (res.status === 201 || res.statusText === "OK") {
        // Indian User Authorized
        setLocalStorage(res.data);
        toast.success("OTP verified successfully!");
      }
    } catch (error) {
      console.log(error);
      const ERROR_MSG = error.response.data.response;
      toast.error(ERROR_MSG || "Something went wrong", {
        duration: 6000,
        position: "top-center",
      });
    } finally {
      navigate("/home");
    }
  };

  // USER INPUTS: FNAME, LASTNAME, EMAIL ONCHANGE HANDLER
  const userInputsOnChange = (event) => {
    setUserInputs((prevState) => {
      return {
        ...prevState,
        [event.target.name]: event.target.value,
      };
    });
  };

  const disableRegisterBtn =
    !userEmail ||
    userEmail.trim() === "" ||
    !userPassword ||
    userPassword === "" ||
    userPassword !== reUserPassword;

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // width: "100vw",
          // height: "100vh",
        }}
      >
        {isLoading ? (
          <div style={{ width: "40%" }}>
            <Loader />
          </div>
        ) : (
          <div
            style={{
              // display: "flex",
              // flexDirection: "column",
              // alignItems: 'center',
              // justifyContent: 'center',
              background:
                "linear-gradient(rgb(169, 198, 217) 15%, rgb(205 222 148) 90%)",
              // height: "37%",
              minHeight: "400px",
              minWidth: "350px",
              padding: "40px",
              borderRadius: "5px",
              boxShadow: "0px 24px 36px rgba(131,153,167,0.99)",
            }}
          >
            <Typography
              color="primary"
              variant="h4"
              sx={{ textAlign: "center", mb: 2 }}
            >
              SIGN-UP
            </Typography>

            {/* NEW CODE FIRSTNAME, LASTNAME, EMAIL */}
            <Stack
              spacing={2}
              sx={{
                margin: "1rem 0",
              }}
            >
              <Stack
                direction="row"
                sx={{
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "0.9rem",
                    textTransform: "uppercase",
                  }}
                >
                  First Name:{" "}
                </Typography>

                <TextField
                  id="firstName"
                  name="firstName"
                  placeholder="First Name"
                  size="small"
                  variant="outlined"
                  value={firstName}
                  onChange={userInputsOnChange}
                  // inputProps={{ maxLength: 6 }}
                />
              </Stack>

              <Stack
                direction="row"
                sx={{
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "0.9rem",
                    textTransform: "uppercase",
                  }}
                >
                  Last Name:{" "}
                </Typography>

                <TextField
                  id="lastName"
                  name="lastName"
                  placeholder="Last Name"
                  size="small"
                  variant="outlined"
                  value={lastName}
                  onChange={userInputsOnChange}
                  // inputProps={{ maxLength: 6 }}
                />
              </Stack>

              <Stack
                direction="row"
                sx={{
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "0.9rem",
                    textTransform: "uppercase",
                  }}
                >
                  Email:
                </Typography>

                <TextField
                  id="userEmail"
                  name="userEmail"
                  placeholder="Email"
                  size="small"
                  variant="outlined"
                  value={userEmail}
                  onChange={userInputsOnChange}
                  // inputProps={{ maxLength: 6 }}
                />
              </Stack>
            </Stack>

            <MobilePhoneInput
              userDetails={userDetails}
              phoneInputOnChange={phoneInputOnChange}
            />

            {/* <PhoneInput
              inputStyle={{ height: 30, width: "100%" }}
              country={"in"}
              value={userDetails.phone}
              onChange={phoneInputOnChange}
              preferredCountries={["us", "in"]}
              inputProps={{
                name: "phone",
                required: userDetails.countryCode === "in",
                autoFocus: true,
                // disabled: userDetails.countryCode !== "in",
              }}
            /> */}

            {userDetails.countryCode === "in" ? (
              <Stack spacing={2} pl={8} pr={8} mt={4} mb={4}>
                <Button onClick={getOTP} variant="contained" size="small">
                  Get OTP
                </Button>
                <TextField
                  type="number"
                  id="mobile-otp"
                  placeholder="OTP"
                  size="small"
                  variant="outlined"
                  value={mobileOTP}
                  onChange={(e) => setMobileOTP(e.target.value)}
                  inputProps={{ maxLength: 6 }}
                />
                <Button
                  onClick={verifyOTPLogin}
                  variant="contained"
                  size="small"
                >
                  Verify OTP & Register
                </Button>
              </Stack>
            ) : (
              <Stack mt={1} spacing={2}>
                {/* <BasicTextFields
                  textFieldType="email"
                  label="Email"
                  value={userEmail}
                  callback={handleUserEmail}
                  fullwidth
                /> */}

                <BasicTextFields
                  textFieldType="password"
                  label="Password"
                  value={userPassword}
                  callback={handleUserPassword}
                  fullwidth
                />
                <BasicTextFields
                  error={userPassword !== reUserPassword}
                  textFieldType="password"
                  label="RePassword"
                  value={reUserPassword}
                  callback={handleReUserPassword}
                  fullwidth
                  helperText={
                    userPassword !== reUserPassword
                      ? "Password doesn't match."
                      : null
                  }
                />
              </Stack>
            )}

            {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
              <BasicTextFields label="Name" value={userFullName} callback={handleUserFirstName} fullwidth />
              
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <BasicTextFields label="Email" value={userEmail} callback={handleUserEmail} fullwidth />
              <BasicTextFields label="Phone" value={userPhone} callback={handleUserPhone} fullwidth />
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <BasicTextFields label="Password" value={userPassword} callback={handleUserPassword} fullwidth />
              <BasicTextFields label="RePassword" value={reUserPassword} callback={handleReUserPassword} fullwidth />
            </div> */}

            <div
              style={{
                marginTop: "30px",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <BasicButton
                disabled={
                  userDetails.countryCode === "in" || disableRegisterBtn
                }
                title="Register"
                callback={handleSubmitRegister}
              />
              <span
                style={{
                  color: "blueviolet",
                  cursor: "pointer",
                  margin: "10px",
                  width: "100%",
                  textAlign: "center",
                }}
                onClick={() => setDisplaySignUpPage(false)}
              >
                Already have an account? Login Here.
              </span>
            </div>
          </div>
        )}
      </div>

      {/* React Hot Toast */}
      <ReactHotToaster />
    </>
  );
};

export default SignUpPage;

// Password Validation: minimum 8 characters, at least 1 symbol, upper and lower case letters and a number
function checkPassword(str) {
  const re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  return re.test(str);
}
