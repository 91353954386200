
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';

import axiosConfig from '../../../axios/axiosConfig';
import { useSelector } from 'react-redux';
import MuiTableSkeletonLoader from '../../muiComps/MuiTableSkeletonLoader';
import ReferralTable from './ReferralTable';
import { Alert, Box, Button, Stack, Typography } from '@mui/material';
import { countUserTypes } from '../../../utils/common/countUserTypes';

const SingleUserReferralTable = () => {
  const {
    user: { userDetails },
  } = useSelector((store) => store.auth);
  const USERID = userDetails.id;

  const { referralCode } = useParams();
  const navigate = useNavigate();

  const [userReferralData, setUserReferralData] = useState();
  const [userTypeCount, setUserTypeCount] = useState({})
  

  const userReferralsQuery = useQuery({
    queryKey: ["user-referrals"],
    queryFn: () => getUserReferrals(USERID, referralCode),
    onSuccess: (data) => {
      setUserReferralData(data.response);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.response || "Something went wrong");
    },
  });

  useEffect(() => {
    // function countUserTypes() {
    //   if (!userReferralData) {
    //     return;
    //   }

    //   const userTypeCounts = {
    //     Free: 0,
    //     Trial: 0,
    //     Admin: 0,
    //     Premium: 0
    //   };
    
    //   for (const user of userReferralData) {
    //     const userType = user.user_type;
    //     if (userTypeCounts.hasOwnProperty(userType)) {
    //       userTypeCounts[userType]++;
    //     }
    //   }
    
    //   setUserTypeCount(userTypeCounts)
      
    // }

    const userTypeCounts = countUserTypes(userReferralData);
    setUserTypeCount(userTypeCounts)
  }, [userReferralData])

  
  return (
    <Box sx={{
      // m: 2,
      padding: '2rem'
    }} >

      <Button variant='contained' size="small" onClick={() => navigate('/admin/users')} >back</Button>

      <Box sx={{
        // margin: '2rem',
        marginTop: '1rem',
        // marginLeft: '4rem'
      }} >
        <Typography variant='h5' >User Types Count</Typography>
        <Stack direction="row" spacing={2} >
          <Typography variant='subtitle' >Free: {userTypeCount?.Free}</Typography>
          <Typography variant='subtitle' >Premium: {userTypeCount?.Premium}</Typography>
          <Typography variant='subtitle' >Admin: {userTypeCount?.Admin}</Typography>
          <Typography variant='subtitle' >Trial: {userTypeCount?.Trial}</Typography>
        </Stack>
      </Box>

      <Typography variant='h4' sx={{
        textAlign: 'center',
        textTransform: 'uppercase',
        mb: 2
      }} >Referral Details</Typography>

      {/* UESR REFERRAL TABLE */}
      {userReferralsQuery.isLoading ? (
        <MuiTableSkeletonLoader />
      ) : (
        <ReferralTable userReferralData={userReferralData} />
      )}

      {/* USER ALERT MESSAGE */}
      <Stack sx={{ mt: 2, display: 'inline-block' }} spacing={2}>
        <Alert severity="warning" sx={{ textTransform: 'uppercase'}} >Some Referred Users might have deleted or deactivated their account!</Alert>
    </Stack>
    </Box>
  )
}

export default SingleUserReferralTable;


// UTLILITY / API FUNCTIONS
export const getUserReferrals = async (admin_user_id, user_referral_code) => {
  if (!user_referral_code || user_referral_code.trim() === "") {
    alert("Referral Code not Provided!!");
    return;
  }

  const res = await axiosConfig({
    method: "get",
    url: `user/admin/referral_detail?userid=${admin_user_id}&referral_code=${user_referral_code}`,
    data: {},
  });

  return res.data;
};
