// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.AdvisorHome_AdvisorHome__2qdBA {\n    min-width: 1400px;\n    margin: 2rem;\n}", "",{"version":3,"sources":["webpack://./src/components/AdvisorDashboard/AdvisorHome/AdvisorHome.module.css"],"names":[],"mappings":";AACA;IACI,iBAAiB;IACjB,YAAY;AAChB","sourcesContent":["\n.AdvisorHome {\n    min-width: 1400px;\n    margin: 2rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AdvisorHome": "AdvisorHome_AdvisorHome__2qdBA"
};
export default ___CSS_LOADER_EXPORT___;
