import React from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import styles from "./TradeCallsFormSummaryModal.module.css";

const TradeCallsFormSummaryModal = ({
    tradesFormInput,
  formattedSelectedPlans,
//   advisoryDuration,
  fileInput,
  handlePublish,
  handleUpdate,
  handleCloseModal,
  isEditing,
  selectedTradeType
}) => {
  const formInputArray = Object.entries(tradesFormInput).map(
    ([key, value]) => ({
      label: key,
      value: value,
    })
  );

  if (!isEditing) {
    formInputArray.push({
      label: "List of Plan ID",
      value: formattedSelectedPlans,
    });
  }


  return (
    <div className={styles.summaryModalContainer}>
      <div className="headingContainer">
        <h2 className={styles.modalTitle}>
          <InfoOutlinedIcon /> Are you sure you want to Publish?
        </h2>
      </div>

      <div className={styles.summaryContainer}>
        {formInputArray.map((item) => {
          let isDisplay = true;

          // if tradeType/segment is "cash", make isDisplay FALSE for list/array items.
          if (selectedTradeType === "cash") {
            isDisplay = !["lotSize", "lotExpiry"].includes(item.label)
          }
          
          return <SummaryItem labelName={item.label} labelValue={item.value} isDisplay={isDisplay} />;
        })}

        {/* <SummaryItem labelName="Duration" labelValue={advisoryDuration} /> */}
        <SummaryItem labelName="file" labelValue={fileInput?.name} />
      </div>

      <div className={styles.publishBtnContainer}>
        {isEditing ? (
          <button onClick={handleUpdate} className={styles.publishBtn}>
            update
          </button>
        ) : (
          <button onClick={handlePublish} className={styles.publishBtn}>
            publish
          </button>
        )}

        <button onClick={handleCloseModal} className={styles.cancelBtn}>
          cancel
        </button>
      </div>
    </div>
  );
};

export default TradeCallsFormSummaryModal;

// Summary item comp
const SummaryItem = ({ labelName, labelValue, isDisplay }) => {
  return (
    <div className={`${styles.symbolNameContainer} ${isDisplay ? "" : styles.hide}`}>
      <p className={styles.labelName}>{labelName}</p>
      <span className={styles.labelValue}>{labelValue}</span>
    </div>
  );
};
