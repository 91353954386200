// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AdvisorTradeCalls_tabsList__-Fc7S {\n  margin: 1rem;\n}\n\n.AdvisorTradeCalls_tabItem__uMQXr {\n  border: 1px solid #76dd43;\n  /* margin: 0.5rem; */\n  padding: 0.5rem 1rem;\n  text-transform: uppercase;\n\n  cursor: pointer;\n}\n.AdvisorTradeCalls_selected__McCPm {\n  background-color: #76dd43;\n}\n.AdvisorTradeCalls_notActive__kLh2J {\n  pointer-events: none;\n  cursor: not-allowed;\n}\n\n.AdvisorTradeCalls_tabItem__uMQXr:hover {\n  background-color: #76dd43;\n  color: #333;\n}\n", "",{"version":3,"sources":["webpack://./src/components/AdvisorDashboard/AdvisorTradeCalls/AdvisorTradeCalls.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,oBAAoB;EACpB,oBAAoB;EACpB,yBAAyB;;EAEzB,eAAe;AACjB;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,oBAAoB;EACpB,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,WAAW;AACb","sourcesContent":[".tabsList {\n  margin: 1rem;\n}\n\n.tabItem {\n  border: 1px solid #76dd43;\n  /* margin: 0.5rem; */\n  padding: 0.5rem 1rem;\n  text-transform: uppercase;\n\n  cursor: pointer;\n}\n.selected {\n  background-color: #76dd43;\n}\n.notActive {\n  pointer-events: none;\n  cursor: not-allowed;\n}\n\n.tabItem:hover {\n  background-color: #76dd43;\n  color: #333;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabsList": "AdvisorTradeCalls_tabsList__-Fc7S",
	"tabItem": "AdvisorTradeCalls_tabItem__uMQXr",
	"selected": "AdvisorTradeCalls_selected__McCPm",
	"notActive": "AdvisorTradeCalls_notActive__kLh2J"
};
export default ___CSS_LOADER_EXPORT___;
