import React, { useState } from "react";

import styles from "./StockAdvisoryForm.module.css";
import { axiosConfig } from "../../../../axios/axiosConfig";
import toast from "react-hot-toast";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import ReactHotToaster from "../../../common/ReactHotToaster";
import MuiModal from "../../../muiComps/MuiModal";
import AdvisorySummaryModal from "./AdvisorySummaryModal";
import MultiPlansSelectCheckBox from "../../../common/MultiPlansSelectCheckBox";

const advisoryTypeOptions = [
  { value: "SHORT", label: "SHORT" },
  { value: "MEDIUM", label: "MEDIUM" },
  { value: "LONG", label: "LONG" },
];

const StockAdvisoryForm = ({
  isEditing,
  setIsEditing,
  advisoryFormInput,
  setAdvisoryFormInput,
  // initialAdvisoryFormInput,
  clearAdvisoryFormInput,
  activeAdvisoryObj,
  exitPriceRef,
}) => {
  const { user } = useSelector((state) => state.auth);
  const USERID = user?.userDetails?.id;

  const [selectedPlans, setSelectedPlans] = useState([]); // list of Plans ID that are eligible for particular advisory
  const formattedSelectedPlans = selectedPlans.join(",");

  const [openAdvisorySummaryModal, setOpenAdvisorySummaryModal] =
    useState(false);

  const [symbolsList, setSymbolsList] = useState([]); // all stocks & symbols list

  const [fileInput, setFileInput] = useState(null); // pdf file input

  const DURATION = {
    SHORT: "0-3 MONTHS",
    MEDIUM: "6-12 MONTHS",
    LONG: "12-36 MONTHS",
  };

  // FIELDS THAT ARE EDITABLE

  // "is_target_hit":true,
  //     "target_hit_date":null,
  //     "is_stoploss_hit":false,
  //     "stoploss_hit_date":null,

  //     "exit_price":null,
  //     "exit_price_date":null,
  //     "profit_loss":"+20%",
  //     "trade_close_in_days":1,
  //     "description":null

  const {
    symbolName,
    advisoryType,
    entryPrice,
    priceRange,
    targetPrice,
    stopLoss,
    description,
    exitPrice,
    profitLoss,
    tradeClosedInDays
  } = advisoryFormInput;

  const ADVISORY_DURATION = DURATION[advisoryFormInput.advisoryType];

  const formInputOnChange = (event) => {
    setAdvisoryFormInput((prevState) => {
      return {
        ...prevState,
        [event.target.name]: event.target.value,
      };
    });
  };

  const fileInputOnChange = (event) => {
    setFileInput(event.target.files[0]);
  };

  // FETCH SYMBOLS LIST QUERY
  const symbolsListQuery = useQuery({
    retry: false,
    refetchOnWindowFocus: false, // Prevent refetch on window focus
    refetchInterval: false, // Disable automatic refetching
    queryKey: ["symbols-list"], // dependency
    queryFn: () => fetchSymbolsList(),
    onSuccess: (data) => {
      setSymbolsList(data.response[0].data);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.response || "Something went wrong");
    },
  });

  // POST ADVISORY FORM TO BACKEND
  const postAdvisoryFormMutation = useMutation({
    mutationFn: () =>
      postAdvisoryForm(
        USERID,
        advisoryFormInput,
        ADVISORY_DURATION,
        fileInput,
        formattedSelectedPlans
      ),
    onSuccess: (data) => {
      toast.success(data?.response);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.response || "Something went wrong");
    },
    onSettled: () => {
      // setAdvisoryFormInput(initialAdvisoryFormInput);
      clearAdvisoryFormInput();
      setFileInput(null);
      setIsEditing(false);
    },
  });

  //   submit advisory form handler
  const handlePublish = () => {
    postAdvisoryFormMutation.mutate();
    setOpenAdvisorySummaryModal(false);
  };

  // EDIT/UPDATE ADVISORY FORM TO BACKEND
  const updateAdvisoryFormMutation = useMutation({
    mutationFn: () =>
      updateAdvisoryForm(
        USERID,
        activeAdvisoryObj.id,
        advisoryFormInput,
        activeAdvisoryObj
        // fileInput,
        // selectedPlans
      ),
    onSuccess: (data) => {
      toast.success(data?.response);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.response || "Something went wrong");
    },
    onSettled: () => {
      // setAdvisoryFormInput(initialAdvisoryFormInput);
      clearAdvisoryFormInput();
      setFileInput(null);
      setIsEditing(false);
    },
  });

  // EDIT / UPDATE ADVISORY FORM
  const handleUpdate = () => {
    updateAdvisoryFormMutation.mutate();
    setOpenAdvisorySummaryModal(false);
    // setIsEditing(false);
  };

  //   submit advisory form handler
  const handleCloseModal = () => {
    setOpenAdvisorySummaryModal(false);
  };

  // CANCEL EDIT HANDLER
  const handleCancelEdit = () => {
    setIsEditing(false);
    clearAdvisoryFormInput();
  };

  const DISABLE_SUBMIT_BTN =
    !entryPrice ||
    entryPrice.trim() === "" ||
    !targetPrice ||
    targetPrice.trim() === "" ||
    !selectedPlans ||
    selectedPlans.length === 0 ||
    !fileInput;

  const DISABLE_CANCEL_EDIT_BTN = !exitPrice || exitPrice.trim() === "";

  return (
    <div className={styles.StockAdvisoryForm}>
      {/* <h1>StockAdvisoryForm</h1> */}
      {/* {isEditing ? <span className={styles.blinkingText} >Editing!!</span>  : ""} */}

      {/* this wrapper holds multiselect plan list and advisory form */}
      <div className={styles.outerFormWrapper}>
        <div className={styles.formWrapper}>
          <h4 className={styles.blinkingText}>
            {isEditing ? "Editing!!" : ""}
          </h4>

          <h2>Advisory Form</h2>
          {/* SYMBOL NAME */}
          <div className={styles.inputControlBox}>
            <p className={styles.label}>Symbol Name</p>

            <select
              disabled={isEditing}
              className={styles.selectedSymbolInput}
              name="symbolName"
              value={symbolName}
              onChange={formInputOnChange}
            >
              {symbolsList.map((item) => {
                return (
                  <option key={item.id} value={item.name}>
                    {item.name}
                  </option>
                );
              })}
            </select>
          </div>

          <fieldset>
            <legend>Select Advisory Type:</legend>

            {advisoryTypeOptions.map((option) => (
              <div>
                <input
                  disabled={isEditing}
                  style={{
                    margin: "0.8rem",
                  }}
                  type="radio"
                  id={option.value}
                  name="advisoryType"
                  value={option.value}
                  checked={advisoryType === option.value}
                  onChange={formInputOnChange}
                />
                <label for={option.value}>{option.label}</label>
              </div>
            ))}
          </fieldset>

          {/* ADVISORY TYPE */}
          <div className={styles.inputControlBox}>
            <p className={styles.label}>DURATION</p>

            {/* DURATION */}
            <div className={styles.advisoryDuration}>
              <p>{ADVISORY_DURATION}</p>
            </div>
          </div>

          {/* PRICE RANGE */}
          <div className={styles.inputControlBox}>
            <p className={styles.label}>Price Range*</p>
            <input
              disabled={isEditing}
              className={styles.entryPriceInput}
              required
              type="text"
              name="priceRange"
              id="priceRange"
              placeholder="eg. ₹125-₹150"
              value={priceRange}
              onChange={formInputOnChange}
            />
          </div>

          {/* STOP LOSS */}
          <div className={styles.inputControlBox}>
            <p className={styles.label}>Stop Loss</p>
            <input
              disabled={isEditing}
              type="text"
              name="stopLoss"
              id="stopLoss"
              value={stopLoss}
              onChange={formInputOnChange}
            />
          </div>

          {/* ENTRY / SUGGESTED PRICE */}
          <div className={styles.inputControlBox}>
            <p className={styles.label}>Entry Price*</p>
            <input
              disabled={isEditing}
              className={styles.entryPriceInput}
              required
              type="text"
              name="entryPrice"
              id="entryPrice"
              value={entryPrice}
              onChange={formInputOnChange}
            />
          </div>

          {/* TARGET PRICE */}
          <div className={styles.inputControlBox}>
            <p className={styles.label}>Target Price*</p>
            <input
              disabled={isEditing}
              type="text"
              required
              name="targetPrice"
              id="targetPrice"
              value={targetPrice}
              onChange={formInputOnChange}
            />
          </div>

          
          {isEditing ? (
            <>
              
              {/* EXIT PRICE */}
              <div className={styles.inputControlBox}>
                <p className={styles.label}>EXIT Price*</p>
                <input
                  ref={exitPriceRef}
                  type="text"
                  required
                  name="exitPrice"
                  id="exitPrice"
                  value={exitPrice}
                  onChange={formInputOnChange}
                />
              </div>
              
              {/* PROFIT LOSS */}
              <div className={styles.inputControlBox}>
                <p className={styles.label}>Profit Loss*</p>
                <input
                  type="text"
                  required
                  name="profitLoss"
                  id="profitLoss"
                  value={profitLoss}
                  onChange={formInputOnChange}
                />
              </div>

              {/* Trade Closed in Days */}
              <div className={styles.inputControlBox}>
                <p className={styles.label}>Trade Closed in Days*</p>
                <input
                  type="text"
                  required
                  name="tradeClosedInDays"
                  id="tradeClosedInDays"
                  value={tradeClosedInDays}
                  onChange={formInputOnChange}
                />
              </div>
              
            </>
          ) : null}

          

          <div className={styles.inputControlBox}>
            <p className={styles.label}>Description</p>
            <textarea
              name="description"
              id="description"
              placeholder="Description for Admin Panel..."
              value={description}
              onChange={formInputOnChange}
            ></textarea>
            
          </div>

          {/* FILE UPLOAD */}
          <div className={styles.inputControlBox}>
            <p className={styles.label}>File*</p>
            <input
              disabled={isEditing}
              required
              type="file"
              name="fileInput"
              onChange={fileInputOnChange}
            />
          </div>

          <div className={styles.btnContainer}>
            <button
              disabled={
                isEditing ? DISABLE_CANCEL_EDIT_BTN : DISABLE_SUBMIT_BTN
              }
              className={styles.submitBtn}
              // onClick={handleSubmitForm}
              onClick={() => setOpenAdvisorySummaryModal(true)}
            >
              view summary
            </button>

            <button
              className={styles.clearBtn}
              onClick={clearAdvisoryFormInput}
            >
              clear
            </button>

            {isEditing ? (
              <button
                // disabled={DISABLE_SUBMIT_BTN}
                className={styles.cancelEditBtn}
                onClick={handleCancelEdit}
              >
                cancel edit
              </button>
            ) : null}
          </div>
        </div>

        {/* multi plans Select component */}
        <div>
          {/* <MultiPlansSelectList
            selectedPlans={selectedPlans}
            setSelectedPlans={setSelectedPlans}
          /> */}

          {isEditing ? null : (
            <MultiPlansSelectCheckBox
              selectedPlans={selectedPlans}
              setSelectedPlans={setSelectedPlans}
            />
          )}
        </div>
      </div>
      {/* outFormWrapper end DIV */}

      {/* summary modal */}
      <MuiModal
        open={openAdvisorySummaryModal}
        setOpen={setOpenAdvisorySummaryModal}
      >
        <AdvisorySummaryModal
          advisoryFormInput={advisoryFormInput}
          advisoryDuration={ADVISORY_DURATION}
          fileInput={fileInput}
          handlePublish={handlePublish}
          handleUpdate={handleUpdate}
          handleCloseModal={handleCloseModal}
          formattedSelectedPlans={formattedSelectedPlans}
          isEditing={isEditing}
        />
      </MuiModal>

      {/* react hot toast */}
      <ReactHotToaster />
    </div>
  );
};

export default StockAdvisoryForm;

// FETCHER FUNCTION SAMPLE
const fetchSymbolsList = async () => {
  const res = await axiosConfig({
    method: "get",
    url: "/home/symbols/list",
    data: {},
  });

  return res.data;
};

// function for both 'POST' request
const postAdvisoryForm = async (
  user_id,
  advisory_form_input,
  advisory_duration,
  file_input,
  selected_plans
) => {
  const {
    symbolName,
    advisoryType,
    entryPrice,
    priceRange,
    targetPrice,
    stopLoss,
    description,
  } = advisory_form_input;

  const formData = new FormData();
  formData.append("symbol", symbolName);
  formData.append("entry_price", entryPrice);
  formData.append("price_range", priceRange);
  formData.append("target_price", targetPrice);
  formData.append("stoploss_price", stopLoss);
  formData.append("adviosry_type", advisoryType);
  formData.append("adviosry_duration", advisory_duration);
  formData.append("description", description);
  formData.append("file", file_input);
  formData.append("visible_to", selected_plans);

  const res = await axiosConfig({
    method: "post",
    url: `/predictions/stocks/${user_id}/advisory`,
    data: formData,
  });

  return res.data;
};

// function for patch request (edit form)
const updateAdvisoryForm = async (
  user_id,
  advisory_id,
  advisory_form_input,
  active_advisory_obj
) => {
  // if (!watchlistId) return;
  const { exitPrice, description, profitLoss, tradeClosedInDays } = advisory_form_input;
  // const {
  //   is_target_hit,
  //   target_hit_date,
  //   is_stoploss_hit,
  //   stoploss_hit_date,
  //   exit_price_date,
  //   profit_loss,
  //   trade_close_in_days,
  // } = active_advisory_obj;

  const res = await axiosConfig({
    method: "patch",
    // url: "/predictions/advisory/589/9/update",
    url: `/predictions/advisory/${user_id}/${advisory_id}/update`,
    data: {
      is_target_hit: true,
      target_hit_date: null,
      is_stoploss_hit: false,
      stoploss_hit_date: null,
      exit_price: exitPrice, // from formInput
      exit_price_date: null,
      profit_loss: profitLoss,
      trade_close_in_days: tradeClosedInDays,
      description: description, // from formInput
    },
  });

  return res.data;
};
