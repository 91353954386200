import React from "react";
// import ColorSignalCircle from "../../../../common/ColorSignalCircle";

import styles from "./TradeCallsTable.module.css";

const TradeCallsTable = ({ allTradesList, handleEditBtn, isEdit }) => {
  return (
    <div className={styles.TradeDetailsTable}>
      <h2>Trades History</h2>

      <table className={styles.tableContainer}>
        <thead>
          <tr>
            <th>#</th>
            <th>Symbol</th>
            <th>Segment</th>
            <th>Signal</th>

            <th>Entry</th>
            <th>Stop Loss</th>
            <th>Target</th>
            <th>Exit</th>
            <th>Exit Date</th>

            <th>Created</th>
            <th>Modified</th>

            <th>Profit / Loss</th>
            <th>Profit / Loss %</th>

            <th>File</th>
            {!isEdit && <th>Actions</th>}
          </tr>
        </thead>

        <tbody className={styles.tableBodyContainer}>
          {allTradesList?.map((item, index) => {
            // const createdDate = new Date(item.created_at).toDateString();
            // const modifiedDate = new Date(item.modified_at).toDateString();
            // const formattedAdvisoryStatus = item?.adviosry_status.trim().toLowerCase() === "closed"
            // className={formattedAdvisoryStatus ? styles.statusClosed : ""}

            return (
              <tr key={item.id}>
                <td>{index + 1}</td>
                <td>{item.symbol_name}</td>
                <td>{item.segment}</td>
                <td>{item.signal?.toUpperCase()}</td>

                <td>{item.entry_price}</td>
                <td>{item.stop_loss}</td>
                <td>{item.target_price}</td>
                <td>{item.exit_price}</td>
                <td>{item?.exit_price_time}</td>

                <td>{item.created_at}</td>
                <td>{item.modified_at}</td>

                <td>{item.profit_loss}</td>
                <td>
                  {item?.profit_loss_percent
                    ? `${item?.profit_loss_percent?.toFixed(2)}%`
                    : "N/A"}
                </td>

                {/* <td>{item.advisoury_end_date}</td> */}

                {/* <td>
                  <div className={styles.colorCircleContainer}>
                    {item.is_target_hit ? (
                      <ColorSignalCircle bgColor="green" />
                    ) : (
                      <ColorSignalCircle bgColor="red" />
                    )}
                  </div>
                </td>
                <td>{item.target_hit_date ? item.target_hit_date : "N/A"}</td> */}

                <td>
                  <div>
                    <a className={styles.fileDownloadLink} href={item?.file}>
                      download
                    </a>
                  </div>
                </td>

                {!isEdit && (
                  <td>
                    <div>
                      <button
                        // disabled={formattedAdvisoryStatus}
                        className={styles.editBtn}
                        onClick={() => handleEditBtn(item)}
                      >
                        edit
                      </button>
                    </div>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default TradeCallsTable;
