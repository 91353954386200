import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { axiosConfig } from "../../axios/axiosConfig";
import TradeCallsTable from "../AdvisorDashboard/AdvisorTradeCalls/common/TradeCallsTable";

const TradeHistory = () => {
  const { user } = useSelector((state) => state.auth);
  const USERID = user?.userDetails?.id;
  const [searchParams] = useSearchParams();
  const [openTrades, setOpenTrades] = useState([]);
  const [closedTrades, setClosedTrades] = useState([]);
  const allTradesList = [...openTrades, ...closedTrades];

  // FETCHING OPEN TRADE CALLS, (cash, options, and futures)
  const openTradeCallsQuery = useQuery({
    retry: false,
    refetchOnWindowFocus: false, // Prevent refetch on window focus
    refetchInterval: false, // Disable automatic refetching
    queryKey: ["open-trades", "cash"], // dependency
    queryFn: () => fetchOpenTrades(USERID, "cash"),
    onSuccess: (data) => {
      //   setAllTradesList(data.response?.data);
      setOpenTrades(data.response?.data);
    },
    onError: (error) => {
      // console.log(" error: ", error);
      toast.error(error?.response?.data?.response || "Something went wrong");
    },
  });

  // FETCHING OPEN TRADE CALLS, (cash, options, and futures)
  const closeTradeCallsQuery = useQuery({
    retry: false,
    refetchOnWindowFocus: false, // Prevent refetch on window focus
    refetchInterval: false, // Disable automatic refetching
    queryKey: ["closed-trades", "cash"], // dependency
    queryFn: () => fetchClosedTrades(USERID, "cash"),
    onSuccess: (data) => {
      setClosedTrades(data.response?.data);
    },
    onError: (error) => {
      // console.log(" error: ", error);
      toast.error(error?.response?.data?.response || "Something went wrong");
    },
  });

  return (
    <div>
      {/* advisory details table */}
      <TradeCallsTable
        allTradesList={allTradesList}
        handleEditBtn={null}
        isEdit={true}
      />
    </div>
  );
};

export default TradeHistory;

// UTILITIES
// fetching all trades (cash, futures, options) (open or closed)
const fetchOpenTrades = async (user_id, selected_trade_type) => {
  // if (!user_id) return;

  const res = await axiosConfig({
    method: "get",
    // url: "/predictions/stocks/advisory?status=OPEN",
    url: `/predictions/call/${user_id}/${selected_trade_type}`,
    data: {},
  });

  return res.data;
};

const fetchClosedTrades = async (user_id, selected_trade_type) => {
  // if (!user_id) return;

  const res = await axiosConfig({
    method: "get",
    // url: "/predictions/stocks/advisory?status=OPEN",
    // url: `/predictions/call/${user_id}/${selected_trade_type}`,
    url: `predictions/${selected_trade_type}/close`,
    data: {},
  });

  return res.data;
};
