import React from "react";

import DashboardIcon from "@mui/icons-material/Dashboard";

import DashboardLayout from "../components/common/DashboardLayout";

const mainListItemsArray = [
  {
    id: 1,
    label: "User Support",
    link: "/agent/home",
    icon: <DashboardIcon />,
  },
  {
    id: 2,
    label: "Stock Advisory History",
    link: "/agent/stock-advisory-History",
    icon: <DashboardIcon />,
  },
  {
    id: 3,
    label: "Trades History",
    link: "/agent/trades-History",
    icon: <DashboardIcon />,
  },
];

export default function AgentDashboardLayout() {
  return <DashboardLayout mainListItems={mainListItemsArray} />;
}
