import React from "react";
import AllUsersTicketHistoryTable from "../AdminDashboard/UserSupportPanel/AllUsersTicketHistoryTable";

const AgentDashboard = () => {
  return (
    <div>
      <AllUsersTicketHistoryTable
        title="Advisor Support Panel"
        userType="advisor"
      />
    </div>
  );
};

export default AgentDashboard;
