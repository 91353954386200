import React, { useRef, useState } from "react";
import AdvisoryDetailsTable from "../AdvisorDashboard/AdvisorHome/AdvisoryDetailsTable";
import toast from "react-hot-toast";
import { useQuery } from "@tanstack/react-query";
import { axiosConfig } from "../../axios/axiosConfig";

const AgentStockAdvisoryHistory = () => {
  const [advisoryStocksList, setAdvisoryStocksList] = useState([]);

  const advisoryQuery = useQuery({
    retry: false,
    refetchOnWindowFocus: false, // Prevent refetch on window focus
    refetchInterval: false, // Disable automatic refetching
    queryKey: ["advisory-details"], // dependency
    queryFn: () => fetchAdvisoryDetails(),
    onSuccess: (data) => {
      setAdvisoryStocksList(data.response?.data);
    },
    onError: (error) => {
      // console.log(" error: ", error);
      toast.error(error?.response?.data?.response || "Something went wrong");
    },
  });

  return (
    <div>
      {/* advisory details table */}
      <AdvisoryDetailsTable
        advisoryDetailsList={advisoryStocksList}
        handleEditBtn={null}
        isEdit={true}
      />
    </div>
  );
};

export default AgentStockAdvisoryHistory;

// UTILITIES
// fetching all stocks advisory (open or closed)
const fetchAdvisoryDetails = async () => {
  // if (!watchlistId) return;

  const res = await axiosConfig({
    method: "get",
    // url: "/predictions/stocks/advisory?status=OPEN",
    url: "/predictions/stocks/advisory",
    data: {},
  });

  return res.data;
};
