import React, { useState } from "react";
import styles from "./MultiPlansSelectCheckBox.module.css"; // Replace with your actual CSS module
import { axiosConfig } from "../../../axios/axiosConfig";
import { useQuery } from "@tanstack/react-query";

// const [selectedPlans, setSelectedPlans] = useState([]); // list of Plans ID that are eligible for particular advisory
const MultiPlansSelectCheckBox = ({ selectedPlans = [], setSelectedPlans }) => {
  // State to track selected plans
  //   const [selectedPlans, setSelectedPlans] = useState([]);
  const [plansData, setPlansData] = useState([]);

  // select all checkboxes, (using another method to select all checkboxes)
  // const selectAllPlans = () => {
  //   const allIDs = plansData.map((item) => item.id);
  //   setSelectedPlans(allIDs);
  // };

  // FETCH SYMBOLS LIST QUERY
  const fetchAllPlansQuery = useQuery({
    retry: false,
    refetchOnWindowFocus: false, // Prevent refetch on window focus
    refetchInterval: false, // Disable automatic refetching
    queryKey: ["all-plans-list"], // dependency
    queryFn: () => fetchAllPlans(),
    onSuccess: (data) => {
      //   setSymbolsList(data.response[0].data);
      setPlansData(data?.response?.data);
    },
    onError: (error) => {
      alert(error?.response?.data?.response || "Something went wrong");
    },
  });

  // Handle checkbox changes
  const onCheckboxChange = (event) => {
    const { value, checked } = event.target;
    const id = Number(value); // Convert value to a number for consistency

    if (checked) {
      // Add the plan ID to the selectedPlans array if it's not already present
      setSelectedPlans((prev) => (prev.includes(id) ? prev : [...prev, id]));
    } else {
      // Remove the plan ID from the selectedPlans array
      setSelectedPlans((prev) => prev.filter((planId) => planId !== id));
    }
  };

  // Handle Select All checkbox change
  const onSelectAllChange = (event) => {
    const { checked } = event.target;

    if (checked) {
      // Select all plans
      const allIds = plansData.map((item) => item.id);
      setSelectedPlans(allIds);
    } else {
      // Deselect all plans
      setSelectedPlans([]);
    }
  };

  // Determine if all checkboxes are selected
  const isAllSelected =
    plansData.length > 0 && selectedPlans.length === plansData.length;

  return (
    <div className={styles.inputControlBox}>
      {/* Display selected plans */}
      {/* <div className={styles.selectedPlansBox}>
        <p>List of selected Plan IDs:</p>
        {selectedPlans.length > 0 ? (
          <p>{selectedPlans.join(", ")}</p>
        ) : (
          <p>No plans selected</p>
        )}
      </div> */}

      <p className={styles.label}>Select Plans</p>

      <div className={styles.checkboxGroup}>
        {/* Select All Checkbox */}
        <label className={`${styles.checkboxLabel} ${styles.selectAllLabel}`}>
          <input
            type="checkbox"
            checked={isAllSelected}
            onChange={onSelectAllChange}
          />
          SELECT ALL
        </label>

        {/* Individual Plan Checkboxes */}
        <div className={styles.plansWrapper}>
          {plansData?.map((item) => (
            <label
              key={item.id}
              className={`${styles.checkboxLabel} ${
                item.active ? styles.inactive : null
              }`}
            >
              <input
                type="checkbox"
                value={item.id}
                checked={selectedPlans.includes(item.id)}
                //   checked={true}
                onChange={onCheckboxChange}
              />
              {`${item.price}-${item.name}-${item.interval}-${item.interval_unit}`}
            </label>
          ))}
        </div>
      </div>

      {/* RESET, CLEAR ALL BUTTON */}
      <div>
        {/* <button onClick={selectAllPlans} className={styles.resetButton}>
          select all
        </button> */}

        <button
          onClick={() => setSelectedPlans([])}
          className={styles.resetButton}
        >
          clear all
        </button>
      </div>
    </div>
  );
};

export default MultiPlansSelectCheckBox;

// FETCHER FUNCTION
const fetchAllPlans = async () => {
  const res = await axiosConfig({
    method: "get",
    url: "/payment/plans",
    data: {},
  });

  return res.data;
};
