import React, { useState } from "react";
import toast from "react-hot-toast";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";

import styles from "./TradeCallsForm.module.css";
import { axiosConfig } from "../../../../../axios/axiosConfig";
import ReactHotToaster from "../../../../common/ReactHotToaster";
import MuiModal from "../../../../muiComps/MuiModal";
import MultiPlansSelectCheckBox from "../../../../common/MultiPlansSelectCheckBox";
import TradeCallsFormSummaryModal from "./TradeCallsFormSummaryModal";

// const advisoryTypeOptions = [
//   { value: "SHORT", label: "SHORT" },
//   { value: "MEDIUM", label: "MEDIUM" },
//   { value: "LONG", label: "LONG" },
// ];

const segmentOptions = [
  { id: 1, label: "INTRADAY", value: "INTRADAY" },
  { id: 2, label: "BTST", value: "BTST" },
  { id: 3, label: "SWING", value: "SWING" },
  // { id: 4, label: "STBT", value: "STBT" },
];

const signalOptions = [
  // { id: 1, label: "", value: "" },
  { id: 2, label: "LONG BUY", value: "long" },
  { id: 3, label: "SHORT SELL", value: "short" },
];

// IMPORTANT: This is a common form to create 'cash', 'futures' and 'options' trades.
const TradeCallsForm = ({
  isEditing,
  setIsEditing,
  tradesFormInput,
  setTradesFormInput,
  selectedTradeType,
  // initialAdvisoryFormInput,
  clearTradesFormInput,
  activeAdvisoryObj,
  // exitPriceRef
}) => {
  const { user } = useSelector((state) => state.auth);
  const USERID = user?.userDetails?.id;

  const [selectedPlans, setSelectedPlans] = useState([]); // list of Plans ID that are eligible for particular advisory
  const formattedSelectedPlans = selectedPlans.join(",");

  const [openAdvisorySummaryModal, setOpenAdvisorySummaryModal] =
    useState(false);

  const [symbolsList, setSymbolsList] = useState([]); // all stocks & symbols list

  const [fileInput, setFileInput] = useState(null); // pdf file input

  //   const DURATION = {
  //     SHORT: "0-3 MONTHS",
  //     MEDIUM: "6-12 MONTHS",
  //     LONG: "12-36 MONTHS",
  //   };

  const {
    symbolName,
    segment,
    signal,
    entryPrice,
    targetPrice,
    stopLoss,
    exitPrice,
    lotSize,
    lotExpiry,
    // profitLoss,
    // tradeClosedInDays,
  } = tradesFormInput;
  
  //   const ADVISORY_DURATION = DURATION[tradesFormInput.advisoryType];
  
  const formInputOnChange = (event) => {
    setTradesFormInput((prevState) => {
      return {
        ...prevState,
        [event.target.name]: event.target.value,
      };
    });
  };

  const fileInputOnChange = (event) => {
    setFileInput(event.target.files[0]);
  };

  // FETCH SYMBOLS LIST QUERY
  const symbolsListQuery = useQuery({
    retry: false,
    refetchOnWindowFocus: false, // Prevent refetch on window focus
    refetchInterval: false, // Disable automatic refetching
    queryKey: ["symbols-list"], // dependency
    queryFn: () => fetchSymbolsList(),
    onSuccess: (data) => {
      setSymbolsList(data.response[0].data);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.response || "Something went wrong");
    },
  });

  // POST ADVISORY FORM TO BACKEND
  const postTradesFormMutation = useMutation({
    mutationFn: () =>
      postTradesForm(
        USERID,
        tradesFormInput,
        fileInput,
        formattedSelectedPlans,
        selectedTradeType
      ),
    onSuccess: (data) => {
      toast.success(data?.response);
      window.location.reload();
    },
    onError: (error) => {
      toast.error(error?.response?.data?.response || "Something went wrong");
    },
    onSettled: () => {
      // setTradesFormInput(initialAdvisoryFormInput);
      clearTradesFormInput();
      setFileInput(null);
      setIsEditing(false);
    },
  });

  //   submit advisory form handler
  const handlePublish = () => {
    postTradesFormMutation.mutate();
    setOpenAdvisorySummaryModal(false);
  };

  // EDIT/UPDATE ADVISORY FORM TO BACKEND
  const updateTradesFormMutation = useMutation({
    mutationFn: () =>
      updateTradesForm(
        USERID,
        tradesFormInput,
        activeAdvisoryObj,
        selectedTradeType
      ),
    onSuccess: (data) => {
      toast.success(data?.response);
      window.location.reload();
    },
    onError: (error) => {
      toast.error(error?.response?.data?.response || "Something went wrong");
    },
    onSettled: () => {
      // setTradesFormInput(initialAdvisoryFormInput);
      clearTradesFormInput();
      setFileInput(null);
      setIsEditing(false);
    },
  });

  // EDIT / UPDATE ADVISORY FORM
  const handleUpdate = () => {
    updateTradesFormMutation.mutate();
    setOpenAdvisorySummaryModal(false);
    // setIsEditing(false);
  };

  //   submit advisory form handler
  const handleCloseModal = () => {
    setOpenAdvisorySummaryModal(false);
  };

  // CANCEL EDIT HANDLER
  const handleCancelEdit = () => {
    setIsEditing(false);
    clearTradesFormInput();
  };

  const DISABLE_SUBMIT_BTN =
    !entryPrice ||
    entryPrice.trim() === "" ||
    // !targetPrice ||
    // targetPrice.trim() === "" ||
    !selectedPlans ||
    selectedPlans.length === 0 ||
    !fileInput;

  const DISABLE_CANCEL_EDIT_BTN = !exitPrice || exitPrice.trim() === "";

  return (
    <div className={styles.StockAdvisoryForm}>
      {/* <h1>StockAdvisoryForm</h1> */}
      {/* {isEditing ? <span className={styles.blinkingText} >Editing!!</span>  : ""} */}

      {/* this wrapper holds multiselect plan list and advisory form */}
      <div className={styles.outerFormWrapper}>
        <div className={styles.formWrapper}>
          <h4 className={styles.blinkingText}>
            {isEditing ? "Editing!!" : ""}
          </h4>

          <h2>Create {selectedTradeType} Trades</h2>

          {/* SYMBOL NAME */}
          <div className={styles.inputControlBox}>
            <p className={styles.label}>Symbol Name</p>

            <select
              disabled={isEditing}
              className={styles.selectedSymbolInput}
              name="symbolName"
              value={symbolName}
              onChange={formInputOnChange}
            >
              {symbolsList.map((item) => {
                return (
                  <option key={item.id} value={item.name}>
                    {item.name}
                  </option>
                );
              })}
            </select>
          </div>

          {/* SEGMENT SELECTION */}
          <div className={styles.inputControlBox}>
            <p className={styles.label}>Select Segment: </p>

            <select
              disabled={isEditing}
              className={styles.selectedSymbolInput}
              name="segment"
              value={segment}
              onChange={formInputOnChange}
            >
              {segmentOptions.map((item) => {
                return (
                  <option key={item.id} value={item.value}>
                    {item.label}
                  </option>
                );
              })}

              {selectedTradeType === "futures" ? (
                <option key={4} value="STBT">
                  STBT
                </option>
              ) : null}
            </select>
          </div>

          {/* SIGNAL SELECTION */}
          {segment === "INTRADAY" || segment === "SWING" ? (
            <div className={styles.inputControlBox}>
              <p className={styles.label}>Select Signal: </p>

              <select
                disabled={isEditing}
                // disabled={!(selectedTradeType === "cash" && segment === "INTRADAY")}
                className={styles.selectedSymbolInput}
                name="signal"
                value={signal}
                onChange={formInputOnChange}
              >
                {signalOptions.map((item) => {
                  return (
                    <option key={item.id} value={item.value}>
                      {item.label}
                    </option>
                  );
                })}
              </select>
            </div>
          ) : null}

          {/* <fieldset>
            <legend>Select Advisory Type:</legend>

            {advisoryTypeOptions.map((option) => (
              <div>
                <input
                  disabled={isEditing}
                  style={{
                    margin: "0.8rem",
                  }}
                  type="radio"
                  id={option.value}
                  name="advisoryType"
                  value={option.value}
                  checked={advisoryType === option.value}
                  onChange={formInputOnChange}
                />
                <label for={option.value}>{option.label}</label>
              </div>
            ))}
          </fieldset> */}

          {/* ADVISORY DURATION not needed for cash, futures */}
          {/* <div className={styles.inputControlBox}>
            <p className={styles.label}>DURATION</p>

            <div className={styles.advisoryDuration}>
              <p>{ADVISORY_DURATION}</p>
            </div>
          </div> */}

          {/* PRICE RANGE not needed for cash, futures */}
          {/* <div className={styles.inputControlBox}>
            <p className={styles.label}>Price Range*</p>
            <input
              disabled={isEditing}
              className={styles.entryPriceInput}
              required
              type="text"
              name="priceRange"
              id="priceRange"
              placeholder="eg. ₹125-₹150"
              value={priceRange}
              onChange={formInputOnChange}
            />
          </div> */}

          {/* ENTRY / SUGGESTED PRICE */}
          <div className={styles.inputControlBox}>
            <p className={styles.label}>Entry Price*</p>
            <input
              disabled={isEditing}
              className={styles.entryPriceInput}
              required
              type="text"
              name="entryPrice"
              id="entryPrice"
              value={entryPrice}
              onChange={formInputOnChange}
            />
          </div>

          {/* TARGET PRICE */}
          <div className={styles.inputControlBox}>
            <p className={styles.label}>Target Price*</p>
            <input
              disabled={isEditing}
              type="text"
              required
              name="targetPrice"
              id="targetPrice"
              value={targetPrice}
              onChange={formInputOnChange}
            />
          </div>

          {isEditing ? (
            <>
              {/* EXIT PRICE */}
              <div className={styles.inputControlBox}>
                <p className={styles.label}>EXIT Price*</p>
                <input
                  // ref={exitPriceRef}
                  type="text"
                  required
                  name="exitPrice"
                  id="exitPrice"
                  value={exitPrice}
                  onChange={formInputOnChange}
                />
              </div>
            </>
          ) : null}

          {/* STOP LOSS */}
          <div className={styles.inputControlBox}>
            <p className={styles.label}>Stop Loss</p>
            <input
              disabled={isEditing}
              type="text"
              name="stopLoss"
              id="stopLoss"
              value={stopLoss}
              onChange={formInputOnChange}
            />
          </div>

          {/* LOT SIZE AND LOT EXPIRY */}
          {selectedTradeType === "futures" ? (
            <>
              {/* LOT SIZE */}
              <div className={styles.inputControlBox}>
                <p className={styles.label}>Lot Size</p>
                <input
                  disabled={isEditing}
                  type="text"
                  name="lotSize"
                  id="lotSize"
                  value={lotSize}
                  onChange={formInputOnChange}
                />
              </div>

              {/* LOT EXPIRY */}
              <div className={styles.inputControlBox}>
                <p className={styles.label}>Lot Expiry</p>
                <input
                  disabled={isEditing}
                  type="date"
                  name="lotExpiry"
                  id="lotExpiry"
                  value={lotExpiry}
                  onChange={formInputOnChange}
                />
              </div>
            </>
          ) : null}

          {/* DESCRIPTION NOT NEEDED IN TRADES, PLS REMOVE LATER */}
          {/* <div className={styles.inputControlBox}>
            <p className={styles.label}>Description</p>
            <textarea
              name="description"
              id="description"
              placeholder="Description for Admin Panel..."
              value={description}
              onChange={formInputOnChange}
            ></textarea>
           </div> */}

          {/* FILE UPLOAD */}
          <div className={styles.inputControlBox}>
            <p className={styles.label}>File*</p>
            <input
              disabled={isEditing}
              required
              type="file"
              name="fileInput"
              accept="application/pdf, .pdf"
              onChange={fileInputOnChange}
            />
          </div>

          <div className={styles.btnContainer}>
            <button
              disabled={
                isEditing ? DISABLE_CANCEL_EDIT_BTN : DISABLE_SUBMIT_BTN
              }
              className={styles.submitBtn}
              // onClick={handleSubmitForm}
              onClick={() => setOpenAdvisorySummaryModal(true)}
            >
              view summary
            </button>

            <button className={styles.clearBtn} onClick={clearTradesFormInput}>
              clear
            </button>

            {isEditing ? (
              <button
                // disabled={DISABLE_SUBMIT_BTN}
                className={styles.cancelEditBtn}
                onClick={handleCancelEdit}
              >
                cancel edit
              </button>
            ) : null}
          </div>
        </div>

        {/* multi plans Select component */}
        <div
          style={{
            display: isEditing ? "none" : "block",
          }}
        >
          <MultiPlansSelectCheckBox
            selectedPlans={selectedPlans}
            setSelectedPlans={setSelectedPlans}
          />

          {/* {isEditing ? null : (
            <MultiPlansSelectCheckBox
              selectedPlans={selectedPlans}
              setSelectedPlans={setSelectedPlans}
            />
          )} */}
        </div>
      </div>
      {/* outFormWrapper end DIV */}

      {/* summary modal */}
      <MuiModal
        open={openAdvisorySummaryModal}
        setOpen={setOpenAdvisorySummaryModal}
      >
        <TradeCallsFormSummaryModal
          tradesFormInput={tradesFormInput}
          //   advisoryDuration={ADVISORY_DURATION}
          fileInput={fileInput}
          handlePublish={handlePublish}
          handleUpdate={handleUpdate}
          handleCloseModal={handleCloseModal}
          formattedSelectedPlans={formattedSelectedPlans}
          isEditing={isEditing}
          selectedTradeType={selectedTradeType}
        />
      </MuiModal>

      {/* react hot toast */}
      <ReactHotToaster />
    </div>
  );
};

export default TradeCallsForm;

// FETCHER FUNCTION SAMPLE
const fetchSymbolsList = async () => {
  const res = await axiosConfig({
    method: "get",
    url: "/home/symbols/list",
    data: {},
  });

  return res.data;
};

// function for both 'POST' request
const postTradesForm = async (
  user_id,
  trades_form_input,
  file_input,
  selected_plans,
  selected_trade_type
) => {
  const {
    symbolName,
    segment,
    signal,
    entryPrice,
    targetPrice,
    stopLoss,
    lotSize,
    lotExpiry,
  } = trades_form_input;

  const formData = new FormData();
  formData.append("file", file_input);
  formData.append("symbol", symbolName);
  formData.append("segment", segment);
  formData.append("signal", signal);
  formData.append("entry_price", entryPrice);
  formData.append("target_price", targetPrice);
  formData.append("stop_loss", stopLoss);
  formData.append("visible_to", selected_plans);

  if (selected_trade_type === "futures") {
    formData.append("lot_size", lotSize);
    formData.append("lot_expiry", lotExpiry);
  }


  const res = await axiosConfig({
    method: "post",
    url: `/predictions/call/${user_id}/${selected_trade_type}`,
    data: formData,
  });

  return res.data;
};

// function for patch request (edit form)
const updateTradesForm = async (
  user_id,
  advisory_form_input,
  active_advisory_obj,
  selected_trade_type
) => {
  // if (!watchlistId) return;
  const { exitPrice, profitLoss, tradeClosedInDays } = advisory_form_input;
  const { id: trade_id } = active_advisory_obj;

  const res = await axiosConfig({
    method: "patch",
    url: `/predictions/call/${user_id}/${selected_trade_type}`,
    data: {
      call_id: trade_id,
      exit_price: exitPrice, // from formInput
    },
  });

  return res.data;
};
