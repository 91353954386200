import React, { useState, useRef, useEffect } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import DeleteIcon from "@mui/icons-material/Delete";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import styles from "./createMarqueeForm.module.css";
import axiosConfig from "../../../axios/axiosConfig";
import ReactHotToaster from "../../common/ReactHotToaster";
import MuiModal from "../../muiComps/MuiModal";
import TinyMCEEditor from "./TinyMCEEditor";
import TinyMCEContentTable from "./TinyMCEContentTable";
import { fetchBannerScreensList } from "../../common/UploadBannerForm";

// Screen List for Publishing content
// const initialScreenListOptions = [
//   { id: 1, label: "Home", value: "home" },
//   { id: 2, label: "Dashboard", value: "dashboard" },
//   { id: 3, label: "Watchlist", value: "watchlist" },
//   { id: 4, label: "Alert", value: "alert" },
//   { id: 5, label: "New Listing", value: "new_listing" },
//   { id: 6, label: "Categories", value: "categories" },
//   { id: 7, label: "Options", value: "options" },
//   { id: 8, label: "Top Gainers", value: "gainer" },
//   { id: 9, label: "Top Savers", value: "loser" },
//   { id: 10, label: "Price Action", value: "price_action" },
//   { id: 11, label: "Intraday Nifty/BankNifty", value: "NiftyBanknifty" },
// ];

const initialInputDetails = {
  marqueeName: "",
  marqueeDesc: "",
  activateDate: "",
  expiryDate: "",
};

export const CreateMarqueeForm = () => {
  const [inputDetails, setInputDetails] = useState(initialInputDetails);
  const { marqueeName, marqueeDesc, activateDate, expiryDate } = inputDetails;

  // marquee list state
  const [marqueeList, setMarqueeList] = useState([]);

  const { user } = useSelector((state) => state.auth);
  const USERID = user?.userDetails?.id;

  const editorRef = useRef(null); // STATE FOR TINYMCE EDITOR

  const [selectedScreenInput, setSelectedScreenInput] = useState("Dashboard");
  const [openTinyEditor, setOpenTinyEditor] = useState(false);
  // const [crudType, setCrudType] = useState("create");
  // const [isEditing, setIsEditing] = useState(false);
  const [bannerScreensList, setBannerScreensList] = useState([]);
  // state for popup content
  const [popupContentList, setPopupContentList] = useState([]);

  useEffect(() => {
    const getBannerScreensList = async () => {
      const data = await fetchBannerScreensList();
      setBannerScreensList(data?.response);
    };
    getBannerScreensList();
  }, []);

  // const appScreenList = popupContentList?.map((item) => item.screen_name);
  // const screenListOptions = initialScreenListOptions?.filter(
  //   (item) => !appScreenList?.includes(item.value)
  // );

  const inputOnChange = (event) => {
    setInputDetails((prevState) => {
      return {
        ...prevState,
        [event.target.name]: event.target.value,
      };
    });
  };

  // CREATE NEW MARQUEE FORM
  const postMarquee = useMutation({
    mutationFn: async () => {
      const res = await axiosConfig({
        method: "post",
        url: "appdashboard/announcement",
        data: {
          userid: USERID,
          announcement_name: marqueeName,
          announcement_description: marqueeDesc,
          activation_date: activateDate,
          expiry_date: expiryDate,
        },
      });
      return res.data;
    },
    onSuccess: (data) => {
      setMarqueeList((prevState) => {
        return [...prevState, data.response];
      });

      setInputDetails(initialInputDetails);
    },
    onError: (error) => {
      toast.error(error.message || "Something went wrong");
    },
  });

  // HANDLE CREATE / POST NEW MARQUEE
  const handleCreateMarquee = (event) => {
    event.preventDefault();
    postMarquee.mutate();
  };

  // FETCH ALL MARQUEE LIST
  const marqueeQuery = useQuery({
    retry: false,
    refetchOnWindowFocus: false, // Prevent refetch on window focus
    refetchInterval: false, // Disable automatic refetching
    queryKey: ["marquee"], // dependency
    queryFn: () => fetchMarqueeList(USERID),
    onSuccess: (data) => {
      setMarqueeList(data.response);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.response || "Something went wrong");
    },
  });

  // DELETE MARQUEE
  const deleteMarquee = async (marquee_id) => {
    try {
      const res = await axiosConfig({
        method: "delete",
        url: `/appdashboard/announcement?announcement_id=${marquee_id}`,
        data: {
          userid: USERID,
        },
      });

      // update state if delete successfully
      setMarqueeList((prevState) => {
        const newMarqueeList = prevState.filter(
          (item) => item.id !== marquee_id
        );
        return newMarqueeList;
      });

      toast.error("Ticker deleted successfully!");
    } catch (error) {
      toast.error(error.message || "Something went wrong");
    }
  };

  // FETCH ALL TINYMCE CONTENT
  const fetchPopupQuery = useQuery({
    retry: false,
    refetchOnWindowFocus: false, // Prevent refetch on window focus
    refetchInterval: false, // Disable automatic refetching
    queryKey: ["popup-content"], // dependency
    queryFn: () => fetchPopupContent(USERID),
    onSuccess: (data) => {
      setPopupContentList(data.response);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.response || "Something went wrong");
    },
  });

  // create content handler
  const handleCreateContent = () => {
    setOpenTinyEditor(true);
  };

  // marqueeName, marqueeDesc, activateDate, expiryDate
  const disableCreateBtn =
    !marqueeName ||
    marqueeName.trim() === "" ||
    !marqueeDesc ||
    marqueeDesc.trim() === "" ||
    !activateDate ||
    activateDate.trim() === "" ||
    !expiryDate ||
    expiryDate.trim() === "";

  return (
    <div>
      <h2>CreateMarqueeForm</h2>

      {/* name, desc, 2 dates */}
      <form className={styles.marqueeForm}>
        <div className={styles.inputBox}>
          <p>Marquee Name:</p>

          <input
            className={styles.nameInput}
            type="text"
            name="marqueeName"
            id="marqueeName"
            value={marqueeName}
            onChange={inputOnChange}
          />
        </div>

        <div>
          <p>Marquee Description:</p>
          <textarea
            className={styles.descInput}
            rows={4}
            name="marqueeDesc"
            id="marqueeDesc"
            value={marqueeDesc}
            onChange={inputOnChange}
          />
        </div>

        <div className={styles.dateContainer}>
          <p>Activate Date: </p>
          <input
            className={styles.activateDateInput}
            type="date"
            name="activateDate"
            id="activateDate"
            value={activateDate}
            onChange={inputOnChange}
            min={new Date().toISOString().slice(0, 10)}
          />
        </div>

        <div className={styles.dateContainer}>
          <p>Expiry Date: </p>
          <input
            className={styles.expiryDateInput}
            type="date"
            name="expiryDate"
            id="expiryDate"
            value={expiryDate}
            onChange={inputOnChange}
            min={new Date().toISOString().slice(0, 10)}
          />
        </div>

        <button
          disabled={disableCreateBtn}
          className={styles.marqueeCreateBtn}
          onClick={handleCreateMarquee}
        >
          create
        </button>
      </form>

      {/* MARQUEE TABLE, previous data */}
      {/* <MuiTable /> */}

      {/* TEMP MARQUEE DETAILS */}
      <div className={styles.marqueeList}>
        {marqueeList?.map((item, index) => {
          return (
            <div key={item.id} className={styles.marqueeItem}>
              <p>{index + 1}.</p>
              <p>{item.announcement_name}</p>
              <p className={styles.marqueeDescCell}>
                {item.announcement_description}
              </p>
              <p>{new Date(item.created_at).toDateString()}</p>
              <p>{new Date(item.expiry_date).toDateString()}</p>

              <DeleteIcon
                className={styles.deleteIcon}
                onClick={() => deleteMarquee(item.id)}
              />
            </div>
          );
        })}
      </div>

      {/* CREATE TINYMCE HERE */}
      <div className={styles.editorContainer}>
        <h2>Create Modal Content</h2>
        <div className={styles.screenListContainer}>
          <p>Pick a Screen:</p>

          <select
            className={styles.selectScreenInput}
            name="selectedScreen"
            value={selectedScreenInput}
            onChange={(event) => setSelectedScreenInput(event.target.value)}
          >
            {bannerScreensList.map((item) => {
              return (
                <option key={item.id} value={item.screen_name}>
                  {item.screen_name}
                </option>
              );
            })}
          </select>

          {/* Buttons Container */}
          <div className={styles.btnContainer}>
            <button
              className={styles.createContentBtn}
              onClick={handleCreateContent}
            >
              Create
            </button>
            {/* <button onClick={handleEditContent} >View/Edit</button>
          <button onClick={handleCreateContent} >Delete</button> */}
          </div>
        </div>
      </div>

      {/* CONTENT DISPLAY TABLE */}
      <TinyMCEContentTable
        tableRowData={popupContentList}
        userID={USERID}
        setOpenTinyEditor={setOpenTinyEditor}
        setPopupContentList={setPopupContentList}
        editorRef={editorRef}
      />

      {/* TinyMCE Modal Popup for creating new content */}
      <MuiModal open={openTinyEditor} setOpen={setOpenTinyEditor}>
        <TinyMCEEditor
          selectedScreen={selectedScreenInput}
          setPopupContentList={setPopupContentList}
          userID={USERID}
          crudType="create"
          editorRef={editorRef}
          setOpen={setOpenTinyEditor}
        />
      </MuiModal>

      {/* REACT HOT TOAST */}
      <ReactHotToaster />
    </div>
  );
};

// UTILITY FUNCTIONS
export const fetchMarqueeList = async (USERID) => {
  if (!USERID) return;

  const res = await axiosConfig({
    method: "get",
    // url: `/appdashboard/announcement?userid=${USERID}&marquee=true`,
    url: `/appdashboard/announcement?userid=${USERID}`,
    data: {},
  });

  return res.data;
};

export const fetchPopupContent = async (USERID) => {
  if (!USERID) return;

  const res = await axiosConfig({
    method: "get",
    url: `/appdashboard/popup_message?userid=${USERID}`,
    data: {},
  });

  return res.data;
};
