import React from "react";
import TradeCallsForm from "../common/TradeCallsForm";
import TradeCallsTable from "../common/TradeCallsTable";

// This component should contain the Form, the multiselect plans list and the table, same as 'Advisor Home'
const SelectedTradeTab = ({
  selectedTradeType,
  isEditing,
  setIsEditing,
  tradesFormInput,
  setTradesFormInput,
  clearTradesFormInput,
  activeAdvisoryObj,
  allTradesList,
  handleEditBtn,
}) => {
  // const [isEditing, setIsEditing] = useState(false);

  return (
    <div>
      {/* <h2>{selectedTradeType}</h2> */}

      {/* <TradeCallsForm /> */}

      <TradeCallsForm
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        tradesFormInput={tradesFormInput}
        setTradesFormInput={setTradesFormInput}
        clearTradesFormInput={clearTradesFormInput}
        activeAdvisoryObj={activeAdvisoryObj}
        // initialTradesFormInput={initialTradesFormInput}
        // exitPriceRef={exitPriceRef}
        selectedTradeType={selectedTradeType}
      />

      {/* advisory details table */}
      <TradeCallsTable
        allTradesList={allTradesList}
        handleEditBtn={handleEditBtn}
      />
    </div>
  );
};

export default SelectedTradeTab;
