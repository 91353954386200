import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { axiosConfig } from "../../../../../axios/axiosConfig";

import styles from "./SingleUserPaymentsTable.module.css";

// const columnHeaders = [
//   { col_id: "id", name: "ID", col_width: 30 },
//   { col_id: "mobile", name: "Mobile", col_width: 100 },
//   { col_id: "email", name: "Email", col_width: 150 },
//   { col_id: "subscription", name: "Subscription", col_width: 50 },
//   { col_id: "userID", name: "User Details" },
// ];
const columnHeaders = [
  { col_id: "id", name: "ID", col_width: 30 },
  { col_id: "transaction_id", name: "Transaction ID", col_width: 110 },
  { col_id: "payment_date", name: "Payment Date", col_width: 110 },
  { col_id: "subscription", name: "Subscription", col_width: 30 },
  { col_id: "duration", name: "Duration", col_width: 30 },
  { col_id: "start_date", name: "Start Date", col_width: 90 },
  { col_id: "end_date", name: "End Date", col_width: 90 },
  { col_id: "days_left", name: "Days Left", col_width: 90 },
  { col_id: "view_invoice", name: "View Invoice", col_width: 110 },
  { col_id: "send_invoice", name: "Send Invoice", col_width: 110 },
];

const tableRowData = [
  {
    id: 1,
    transaction_id: "#1234567890",
    payment_date: "02-01-2024",
    subscription: "free",
    duration: "1 month",
    start_date: "02-01-2024",
    end_date: "01-02-2024",
    days_left: "21",
    view_invoice: "view",
    send_invoice: "send",
  },
  {
    id: 2,
    transaction_id: "#8564567367",
    payment_date: "05-01-2024",
    subscription: "50 Alerts",
    duration: "1 month",
    start_date: "06-01-2024",
    end_date: "05-02-2024",
    days_left: "23",
    view_invoice: "view",
    send_invoice: "send",
  },
  {
    id: 3,
    transaction_id: "#1564567754",
    payment_date: "02-02-2024",
    subscription: "watchlist",
    duration: "3 month",
    start_date: "02-02-2024",
    end_date: "01-03-2024",
    days_left: "29",
    view_invoice: "view",
    send_invoice: "send",
  },
  {
    id: 4,
    transaction_id: "#2678456735",
    payment_date: "05-01-2024",
    subscription: "Options",
    duration: "6 month",
    start_date: "06-01-2024",
    end_date: "05-02-2024",
    days_left: "15",
    view_invoice: "view",
    send_invoice: "send",
  },
];

// Headings List for Searching
const searchListOptions = [
  { id: 1, label: "Transaction ID", value: "transaction_id" },
  { id: 2, label: "Payment Date", value: "payment_date" },
  { id: 3, label: "Subscription", value: "subscription" },
  { id: 4, label: "Duration", value: "duration" },
  { id: 5, label: "Start Date", value: "start_date" },
];

const SingleUserPaymentsTable = () => {
  const navigate = useNavigate();
  // userid will come from url

  // Get the userId param from the URL.
  // let { userID } = useParams();
  // console.log("searchParams: ", userID);

  const [userPaymentDetails, setUserPaymentDetails] = useState(tableRowData);
  const [searchType, setSearchType] = useState("transaction_id");
  const [searchInput, setSearchInput] = useState("");

  // const [filteredDetails, setFilteredDetails] = useState();
  console.log("searchInput: ", searchInput);
  console.log("userPaymentDetails: ", userPaymentDetails);

  // const filterPaymentDetails = (search_type) => {
  //   const filteredDee = userPaymentDetails?.filter((item) => {
  //     return item[search_type];
  //   });
  // };

  // FETCH USING useQUERY
  const userPaymentsQuery = useQuery({
    queryKey: ["single-user-payments"], // dependency
    queryFn: () => fetchUserPaymentDetails(),
    onSuccess: (data) => {
      // setUserPaymentDetails(data.response);
    },
    onError: (error) => {
      console.log("user payments error: ", error);
    },
  });

  // HANDLE SEARCH FUNCTION, (search by payment date, transaction ID, etc.)
  const handleSearch = () => {
    if (!searchInput) {
      alert("Search Input is Empty!");
    }
    const searchedResultsArray = userPaymentDetails?.filter(
      (item) => item.transaction_id === searchInput
    );
    setUserPaymentDetails(searchedResultsArray);
  };

  // CLEAR ALL SEARCH
  // const clearSearch = () => {
  //   setUserPaymentDetails(tableRowData);
  // }

  // const SEARCH_BTN_DISABLE = !searchInput || searchInput.trim() === "" ;

  return (
    <div className={styles.pageContainer}>
      <button
        className={styles.backBtn}
        onClick={() => navigate("/admin/payments-dashboard")}
      >
        back
      </button>

      <h1 className={styles.pageTitle}>User Details</h1>

      {/* SEARCH INPUTS & FIELDS */}
      {/* label: SearchBy, headings list to search, go button */}
      <div className={styles.searchContainer}>
        <div className={styles.searchListContainer}>
          <h4>Search by</h4>
          {/* <label htmlFor="searchList">Search by</label> */}

          <div className={styles.searchInputContainer}>
            <select
              name="searchList"
              id="searchList"
              value={searchType}
              onChange={(event) => setSearchType(event.target.value)}
            >
              {searchListOptions.map((item) => {
                return (
                  <option key={item.id} value={item.value}>
                    {item.label}
                  </option>
                );
              })}
            </select>

            <input
              type="text"
              name="searchInput"
              id="searchInput"
              placeholder="#1234567890"
              value={searchInput}
              onChange={(event) => setSearchInput(event.target.value)}
            />
          </div>

          <div className="searchButton">
            <button onClick={handleSearch}>Search</button>
            <button onClick={handleSearch}>clear</button>
          </div>
        </div>

        <div className={styles.dateRangeContainer}>
          <h4>Filter by Date Range</h4>

          <div className={styles.startDateContainer}>
            <label htmlFor="">Start Date</label>
            <input type="date" name="" id="" />
          </div>

          <div className={styles.endDateContainer}>
            <label htmlFor="">End Date</label>
            <input type="date" name="" id="" />
          </div>

          <div className="searchByDateBtnContainer">
            <button>submit</button>
          </div>
        </div>
      </div>

      {/* SINGLE USER PAYMENT DETAILS TABLE */}
      <div className={styles.tableContainer}>
        <table className={styles.table}>
          <thead>
            <tr>
              {columnHeaders.map((column) => (
                <th
                  key={column.col_id}
                  style={{
                    width: column?.col_width,
                  }}
                >
                  {column.name}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {userPaymentDetails.map((row) => (
              <tr key={row.id}>
                {columnHeaders.map((column) => {
                  const dataItem = row[column.col_id];

                  if (column.col_id === "userID") {
                    return (
                      <td>
                        <Link to={`user/${dataItem}`}>view</Link>
                      </td>
                    );
                  }

                  return <td key={column.col_id}>{dataItem}</td>;
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SingleUserPaymentsTable;

// FETCHER FUNCTION SAMPLE
export const fetchUserPaymentDetails = async () => {
  // if (!watchlistId) return;
  return;

  const res = await axiosConfig({
    method: "get",
    url: ``,
    data: {},
  });

  return res.data;
};
