import React, { useEffect, useState } from "react";
// import BasicButton from "../Button/BasicButton";
import BasicTextFields from "../Modal/Input";

import { useGoogleLogin } from "@react-oauth/google";

import { login, googleLogin } from "../../reduxStore/actions/auth";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Loading/Loading";

import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import toast from "react-hot-toast";

import {
  Button,
  FormControl,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

// import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/style.css";
import "react-phone-input-2/lib/material.css";
import MobilePhoneInput from "./MobilePhoneInput";
import GoogleLogin from "./GoogleLogin";
import axios from "axios";
import ReactHotToaster from "../common/ReactHotToaster";
import { Link, useSearchParams } from "react-router-dom";
// import { sendDeviceID } from "../../utils/notifications";

const SignInPage = ({
  // displaySignUpPage,
  setDisplaySignUpPage,
  getOTPLogin,
  verifyOTPLogin,
  userEmail,
  setUserEmail,
  userPassword,
  setUserPassword,
  userDetails,
  setUserDetails,
  mobileOTP,
  setMobileOTP,
  phoneInputOnChange,
  routeToHome,
}) => {
  // const [userId, setUserId] = useState("");
  // const [userEmail, setUserEmail] = useState("");
  // const [userPassword, setUserPassword] = useState("");
  // const [userDetails, setUserDetails] = useState({
  //   phone: "",
  //   country: "",
  //   countryCode: "",
  // });

  const dispatch = useDispatch();

  const [isLoading, setisLoading] = useState(false);
  // const [otpTimer, setOtpTimer] = useState(0);

  // const { isLoggedIn } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);

  const [showPassword, setShowPassword] = React.useState(false);

  const [searchParams] = useSearchParams();
  const REFERRAL_CODE = searchParams.get("referral_code");

  useEffect(() => {
    if (!!REFERRAL_CODE) {
      setDisplaySignUpPage(true);
    }
  }, [REFERRAL_CODE]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // const startTimer = () => {
  //   if (otpTimer === 30) return;

  //   const interval = setInterval(() => {
  //     setOtpTimer((prev) => prev + 1);
  //   }, 1000);
  // };

  // handling onchange password
  const handleUserPassword = (event) => {
    event.preventDefault();
    // console.log(event);
    setUserPassword(event.target.value);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    e.defaultMuiPrevented = true;

    setisLoading(true);

    await dispatch(login(userEmail, userPassword, userDetails.phone.slice(2)))
      .then(() => {
        // sendDeviceID()

        routeToHome();
      })
      .catch((error) => {
        // console.log("error: ", error.message)
        toast.error(
          error.message || message || "Something went wrong, please try again!",
          {
            duration: 6000,
            position: "top-center",
          }
        );
      })
      .finally(() => {
        setisLoading(false);
      });

    // try {
    //   dispatch(login(userEmail, userPassword, userDetails.phone))
    //   routeToHome();

    // } catch (error) {
    //   // error is undefined here
    //   console.log("error: ", error)
    //   toast.error(message || "Something went wrong, please try again!", {
    //     duration: 6000,
    //     position: 'top-center',
    //   });
    // } finally {
    //   setisLoading(false);
    // }
  };

  // LOGIN TO GOOGLE FUNCTION
  const loginToGoogle = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        const userData = await getUserData(tokenResponse.access_token);

        // password and phone is undefined / NULL here
        // EITHER USE AWAIT OR THEN, CATCH BLOCK
        await dispatch(googleLogin(userData.email));
        routeToHome();
      } catch (error) {
        console.log(error);
      } finally {
        // console.log("INSIDE FINALLY BLOCK GOOGLE");
      }
    },
  });

  const disableLoginBtn =
    !userEmail ||
    userEmail.trim() === "" ||
    !userPassword ||
    userPassword === "";

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // width: "100vw",
          height: "100vh",
        }}
      >
        {isLoading ? (
          <div style={{ width: "40%" }}>
            <Loader />
          </div>
        ) : (
          <div
            style={{
              background:
                "linear-gradient(rgb(169, 198, 217) 15%, rgb(205 222 148) 90%)",
              minHeight: "400px",
              minWidth: "350px",
              padding: "40px",
              borderRadius: "5px",
              boxShadow: "0px 24px 36px rgba(131,153,167,0.99)",
            }}
          >
            <Typography
              color="primary"
              variant="h4"
              sx={{ textAlign: "center", mb: 2 }}
            >
              LOGIN
            </Typography>

            <MobilePhoneInput
              userDetails={userDetails}
              phoneInputOnChange={phoneInputOnChange}
            />

            {/* <PhoneInput
              inputStyle={{ height: 25, width: "100%" }}
              placeholder="Mobile Number"
              country={"in"}
              value={userDetails.phone}
              onChange={phoneInputOnChange}
              preferredCountries={["us", "in"]}
              inputProps={{
                name: "phone",
                required: userDetails.countryCode === "in", // true if user is Indian
                autoFocus: true,
                // disabled: userDetails.countryCode !== "in", // disabled if user is non-Indian
              }}
            /> */}

            {userDetails.countryCode === "in" ? (
              <Stack spacing={2} pl={8} pr={8} mt={4} mb={4}>
                <Button onClick={getOTPLogin} variant="contained" size="small">
                  SEND OTP
                </Button>
                <TextField
                  id="mobile-otp"
                  placeholder="OTP"
                  size="small"
                  variant="outlined"
                  inputProps={{ maxLength: 6 }}
                  value={mobileOTP}
                  onChange={(e) => setMobileOTP(e.target.value)}
                />
                <Button
                  onClick={verifyOTPLogin}
                  variant="contained"
                  size="small"
                >
                  Verify OTP & Login
                </Button>
              </Stack>
            ) : (
              <Stack spacing={1} mt={2}>
                <BasicTextFields
                  label="Email"
                  value={userEmail}
                  callback={(key, val) => {
                    setUserEmail(val);
                  }}
                  fullwidth
                />
                {/* <BasicTextFields
                  textFieldType="password"
                  label="Password"
                  value={userPassword}
                  callback={handleUserPassword}
                  fullwidth
                /> */}

                <FormControl variant="outlined">
                  <InputLabel sx={{}} htmlFor="outlined-adornment-password">
                    Password
                  </InputLabel>
                  <OutlinedInput
                    sx={{ backgroundColor: "#fff", borderRadius: 1 }}
                    value={userPassword}
                    onChange={handleUserPassword}
                    size="small"
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    // label="Password"
                  />
                </FormControl>

                {/* FORGET PASSWORD */}
                <Link to="/forget-password">Forget Password?</Link>
              </Stack>
            )}
            {/* <BasicTextFields
              label="Password"
              value={userPassword}
              textFieldType="password"
              callback={handleUserPassword}
              // onKeyDown={handleEnterKey}
              onKeyDown={onKeyDown}
              fullwidth
            /> */}
            <div
              style={{
                marginTop: "30px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                // justifyContent: 'center',
                width: "100%",
              }}
            >
              {/* <BasicButton title="Login" callback={handleLogin} onKeyDown={handleEnterKey}  /> */}
              {/* <BasicButton title="Login" callback={handleLogin} /> */}
              <Button
                type="submit"
                disabled={userDetails.countryCode === "in" || disableLoginBtn}
                variant="contained"
                // onKeyUp={onKeyUp}
                onClick={handleLogin}
                sx={{ width: "100px" }}
              >
                Login
              </Button>
              <span
                style={{
                  color: "blueviolet",
                  cursor: "pointer",
                  margin: "10px",
                  width: "100%",
                  textAlign: "center",
                }}
                onClick={() => setDisplaySignUpPage(true)}
              >
                Don't have an account? Sign Up Here.
              </span>

              {/* GOOGLE LOGIN BUTTON */}
              <GoogleLogin
                isCountrySelected={false}
                loginToGoogle={loginToGoogle}
              />
            </div>
          </div>
        )}
      </div>

      {/* React Hot Toast */}
      <ReactHotToaster />
    </>
  );
};

export default SignInPage;

// UTILITY FUNCTIONS
export const getUserData = async (accessToken) => {
  try {
    const { data } = await axios.get(
      "https://www.googleapis.com/oauth2/v3/userinfo",
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return data;
  } catch (error) {
    return null;
  }
};

// RESPONSE FROM GOOGLE after sending token
// email: "tnibroreact@gmail.com"
// email_verified: true
// family_name: "React"
// given_name: "TBIBRO"
// locale: "en"
// name: "TBIBRO React"
// picture: "https://lh3.googleusercontent.com/a/AGNmyxaFAkyvh2_NBqyv7blU7VQ4wwJLEcwycAcDaSuq=s96-c"
// sub: "102878586108664140472"
