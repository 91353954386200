import React from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import styles from "./AdvisorySummaryModal.module.css";

const AdvisorySummaryModal = ({
  advisoryFormInput,
  formattedSelectedPlans,
  advisoryDuration,
  fileInput,
  handlePublish,
  handleUpdate,
  handleCloseModal,
  isEditing,
}) => {
  const formInputArray = Object.entries(advisoryFormInput).map(
    ([key, value]) => ({
      label: key,
      value: value,
    })
  );

  if (!isEditing) {
    formInputArray.push({
      label: "List of Plan ID",
      value: formattedSelectedPlans,
    });
  }


  return (
    <div className={styles.summaryModalContainer}>
      <div className="headingContainer">
        <h2 className={styles.modalTitle}>
          <InfoOutlinedIcon /> Are you sure you want to Publish?
        </h2>
      </div>

      <div className={styles.summaryContainer}>
        {formInputArray.map((item) => {
          return <SummaryItem labelName={item.label} labelValue={item.value} />;
        })}

        <SummaryItem labelName="Duration" labelValue={advisoryDuration} />
        <SummaryItem labelName="file" labelValue={fileInput?.name} />
      </div>

      <div className={styles.publishBtnContainer}>
        {isEditing ? (
          <button onClick={handleUpdate} className={styles.publishBtn}>
            update
          </button>
        ) : (
          <button onClick={handlePublish} className={styles.publishBtn}>
            publish
          </button>
        )}

        <button onClick={handleCloseModal} className={styles.cancelBtn}>
          cancel
        </button>
      </div>
    </div>
  );
};

export default AdvisorySummaryModal;

// Summary item comp
const SummaryItem = ({ labelName, labelValue }) => {
  return (
    <div className={styles.symbolNameContainer}>
      <p className={styles.labelName}>{labelName}</p>
      <span className={styles.labelValue}>{labelValue}</span>
    </div>
  );
};
