import React, { useState, useEffect } from "react";
import UserPaymentsDashboardTable from "./UserPaymentsDashboardTable";
import { axiosConfig } from "../../../axios/axiosConfig";
import toast from "react-hot-toast";
import ReactHotToaster from "../../common/ReactHotToaster";

const styles = {
  pageContainer: {
    margin: "2rem",
  },
  pageTitle: {
    marginBottom: "2rem",
  },
};

const PaymentsDashboard = () => {
  const [allUserPaymentDetails, setAllUserPaymentDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    const fetchPayments = async () => {
      try {
        setLoading(true); // Start loading
        const data = await fetchAllUserPayments();
        const paid_amt = data.response.reduce(
          (acc, item) => acc + parseFloat(item.amount),
          0
        );
        setTotalCount(data.response.length);
        setTotalAmount(paid_amt);
        setAllUserPaymentDetails(data.response);
      } catch (error) {
        toast.error(error?.response?.data?.response || "Something went wrong");
      } finally {
        setLoading(false); // End loading
      }
    };

    // Fetch payments only if not already loading
    if (!loading) {
      fetchPayments();
    }
  }, []); // Empty dependency array to run only once

  //handle Date Filter
  const filterData = () => {
    const startDate = new Date(fromDate);
    const endDate = new Date(toDate);
    const filteredPayments = allUserPaymentDetails?.filter((payment) => {
      const paymentDate = new Date(
        payment.payment_date?.split("-").reverse().join("-")
      );
      const isWithinRange = paymentDate >= startDate && paymentDate <= endDate;
      return isWithinRange;
    });
    let paid_amt = filteredPayments.reduce(
      (acc, item) => acc + parseFloat(item.amount),
      0
    );
    setTotalCount(filteredPayments.length);
    setTotalAmount(paid_amt);
    setAllUserPaymentDetails(filteredPayments);
  };

  return (
    <div style={styles.pageContainer}>
      <h1 style={styles.pageTitle}>Payments Dashboard</h1>

      {/* USER PAYMENTS DASHBOARD TABLE */}
      {loading ? (
        <h1>Loading..</h1>
      ) : (
        <UserPaymentsDashboardTable
          allUserPaymentDetails={allUserPaymentDetails}
          setAllUserPaymentDetails={setAllUserPaymentDetails}
          filterData={filterData}
          setFromDate={setFromDate}
          setToDate={setToDate}
          toDate={toDate}
          fromDate={fromDate}
          totalCount={totalCount}
          totalAmount={totalAmount}
        />
      )}

      {/* REACT HOT TOAST */}
      <ReactHotToaster />
    </div>
  );
};

export default PaymentsDashboard;

export const fetchAllUserPayments = async () => {
  const res = await axiosConfig({
    method: "get",
    url: "/payment/payments",
    data: {},
  });

  return res.data;
};
